import React, { useCallback, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ROUTES } from 'const';
import { MainPage } from 'containers/Salary/MainPage';
import { EmployeesPage } from 'containers/Salary/EmployeesPage';
import { CompanySettingsScheduleDetail } from 'containers/Salary/CompanySettingsPage/CompanySettingsScheduleDetail';
import { CSManageRewardFamily } from 'containers/Salary/CompanySettingsPage/CSManageRewardFamily';
import { CompanySettingsPage } from 'containers/Salary/CompanySettingsPage';
import { RewardsPage } from 'containers/Salary/RewardsPage';
import { CalendarPage } from 'containers/Salary/CalendarPage';
import { Switch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserCurrentCompany } from 'modules/loggedUserInfo/selectors';
import {
  fetchCompanyBranchesRequest,
  fetchEmployeesRequest,
  setEmployeesFilters,
} from 'modules/SalaryModule/Employees/actions';
import { getAbsencesTypesRequest } from 'modules/SalaryModule/Calendar/actions';

import { fetchSchedulesRequest } from 'modules/SalaryModule/CompanySettings/actions';
import { AddVacationModal } from 'containers/Salary/EmployeesPage/AddVacationModal';
import { toggleVacationModalVisibility } from 'modules/SalaryModule/Vacations/actions';
import { PAY_ROUTES_REDIRECTS } from 'const/RoutesRedirects';

export const SalaryContainer = React.memo(() => {
  const dispatch = useDispatch();
  const { id: companyId, managedSilae, payEnabled } = useSelector(
    selectUserCurrentCompany
  );

  useEffect(() => {
    if (companyId !== 'X-TEST-API' || !managedSilae || !payEnabled) {
      dispatch(fetchEmployeesRequest({ companyId }));
    }

    dispatch(fetchSchedulesRequest(companyId));
    dispatch(fetchCompanyBranchesRequest(companyId));
    dispatch(getAbsencesTypesRequest());
    dispatch(
      setEmployeesFilters({
        establishments: '',
        natureEmployments: '',
        classifications: '',
        contracts: '',
      })
    );
  }, [dispatch, companyId, managedSilae, payEnabled]);

  const toggleModalVisibility = useCallback(() => {
    dispatch(toggleVacationModalVisibility());
  }, [dispatch]);

  return (
    <>
      <AddVacationModal onClose={toggleModalVisibility} />
      <Switch>
        <Route exact path={ROUTES.SALARIES_MAIN_PAGE} component={MainPage} />
        <Route
          exact
          path={ROUTES.SALARIES_EMPLOYEES}
          component={EmployeesPage}
        />
        <Route
          exact
          path={ROUTES.SALARIES_COMPANY_SETTINGS_SCHEDULE}
          component={CompanySettingsScheduleDetail}
        />
        <Route
          exact
          path={ROUTES.SALARIES_COMPANY_SETTINGS_REWARD}
          component={CSManageRewardFamily}
        />
        <Route
          exact
          path={ROUTES.SALARIES_COMPANY_SETTINGS}
          component={CompanySettingsPage}
        />
        <Route exact path={ROUTES.SALARIES_REWARDS} component={RewardsPage} />
        <Route exact path={ROUTES.SALARIES_CALENDAR} component={CalendarPage} />
        {PAY_ROUTES_REDIRECTS.map(({ to, from }) => (
          <Redirect exact key={`${from}_${to}`} to={to} from={from} />
        ))}
      </Switch>
    </>
  );
});
