import { createSelector } from 'reselect';
import { APP_MODULES, CURRENT_YEAR } from 'const';
import { getUserPackagesFromModules } from 'modules/loggedUserInfo/helpers';

const {
  APPROVE_INVOICE,
  READONLY_INVOICE,
  PAY_INVOICE,
  APPROVE_AND_PAY_INVOICE,
  ADDITIONAL_USER,
  DASHBOARD_HR,
  PRE_VALIDATE_INVOICE,
} = APP_MODULES;

export const selectUserTermsOfUseAcceptance = (state) =>
  state.getIn(['loggedUserInfo', 'info', 'data', 'user', 'termsAccepted']);

const selectUserCompanies = createSelector(
  (state) => state.getIn(['loggedUserInfo', 'info', 'data', 'companies']),
  (values) => (values ? values.toJS() : [])
);

export const selectUserCompaniesListData = createSelector(
  selectUserCompanies,
  (companiesArray) => companiesArray.map(({ id, name }) => ({ id, name }))
);

export const selectUserInfoStatus = (state) =>
  state.getIn(['loggedUserInfo', 'info', 'status']);

export const selectUserNPS = createSelector(
  (state) => state.getIn(['loggedUserInfo', 'info', 'data', 'user', 'nps']),
  (values) => (values ? values.toJS() : [])
);

export const selectUserPreValidateLimit = (state) =>
  state.getIn(['loggedUserInfo', 'info', 'data', 'user', 'preValidateLimit']);

export const selectUserNotifications = createSelector(
  (state) =>
    state.getIn(['loggedUserInfo', 'info', 'data', 'user', 'notifications']),
  (values) => (values ? values.toJS() : [])
);

export const selectUserNotificationIndex = (state) =>
  state.getIn(['loggedUserInfo', 'notificationIndex']);

export const selectUserNotification = createSelector(
  selectUserNotifications,
  selectUserNotificationIndex,
  (notifications, index) => notifications[index] || {}
);

export const selectIsUserNotification = createSelector(
  selectUserNotifications,
  selectUserNotificationIndex,
  (notifications, index) => notifications[index] || false
);

export const selectIsUserNextNotification = createSelector(
  selectUserNotifications,
  selectUserNotificationIndex,
  (notifications, index) => notifications[index + 1] || false
);

export const selectUserInfoFullName = (state) =>
  `${state.getIn([
    'loggedUserInfo',
    'info',
    'data',
    'user',
    'firstName',
  ])} ${state.getIn(['loggedUserInfo', 'info', 'data', 'user', 'lastName'])}`;

export const selectUserCurrentCompany = createSelector(
  (state) => state.getIn(['loggedUserInfo', 'info', 'data', 'current']),
  (company) => (company ? company.toJS() : {})
);

export const selectUserCurrentCompanyPreCompta = createSelector(
  (state) =>
    state.getIn(['loggedUserInfo', 'info', 'data', 'current', 'preCompta']),
  (preCompta) => preCompta
);

export const selectUserCurrentCompanyPreComptaMailCollector = createSelector(
  (state) =>
    state.getIn([
      'loggedUserInfo',
      'info',
      'data',
      'current',
      'preComptaMailCollector',
    ]),
  (preCompta) => preCompta
);

export const selectUserCurrentCompanyPreComptaMail = createSelector(
  (state) =>
    state.getIn(['loggedUserInfo', 'info', 'data', 'current', 'preComptaMail']),
  (preCompta) => preCompta
);

const selectUserCurrentCompanyDashboardInfo = (state) =>
  state
    .getIn(['loggedUserInfo', 'info', 'data', 'companiesDashboardData'])
    .toJS()[0];

export const selectUserCurrentCompanyExercice = createSelector(
  selectUserCurrentCompanyDashboardInfo,
  (currentCompanyInfo) =>
    currentCompanyInfo?.data ? currentCompanyInfo.data.currentExercice : ''
);

export const selectUserCurrentCompanyExerciceWithYear = createSelector(
  selectUserCurrentCompanyExercice,
  (currentExercice) => ({
    currentExercice,
    currentYearFromExercice:
      (currentExercice && currentExercice.slice(0, 4)) || CURRENT_YEAR,
  })
);

export const selectUserCurrentCompanyMaxPeriod = createSelector(
  selectUserCurrentCompanyDashboardInfo,
  (currentCompanyInfo) =>
    currentCompanyInfo.data ? currentCompanyInfo.data.maxPeriod : ''
);

export const selectUserCurrentCompanyTransition = createSelector(
  selectUserCurrentCompanyDashboardInfo,
  (currentCompanyInfo) =>
    currentCompanyInfo.data ? currentCompanyInfo.data.inTransition : ''
);

export const selectUserCurrentCompanyDosId = createSelector(
  selectUserCurrentCompany,
  (currentCompanyInfo) => currentCompanyInfo && currentCompanyInfo.id
);

export const selectUserCurrentCompanyActivatedStatus = createSelector(
  selectUserCurrentCompany,
  (currentCompanyInfo) => currentCompanyInfo && currentCompanyInfo.activated
);

export const selectUserCurrentCompanyReliability = createSelector(
  selectUserCurrentCompanyDashboardInfo,
  (currentCompanyInfo) =>
    (currentCompanyInfo &&
      currentCompanyInfo.data &&
      currentCompanyInfo.data.reliabilityIndex) ||
    'N/A'
);

export const selectUserPreviewFinancials = createSelector(
  (state) => state.getIn(['loggedUserInfo', 'info', 'data', 'financials']),
  (financials) => (financials ? financials.toJS() : {})
);

export const selectEmailOfHrManager = (state) =>
  state.getIn([
    'loggedUserInfo',
    'info',
    'data',
    'current',
    'emailOfHRManager',
  ]);

export const selectUserEmail = (state) =>
  state.getIn(['loggedUserInfo', 'info', 'data', 'user', 'email']);

export const selectIsXPBCompany = (state) =>
  state.getIn(['loggedUserInfo', 'info', 'data', 'current', 'managedXpb']);

export const selectUserCompanyId = (state) =>
  state.getIn(['loggedUserInfo', 'info', 'data', 'current', 'id']);

export const selectUserAuthorizedModules = createSelector(
  (state) =>
    state.getIn(['loggedUserInfo', 'info', 'data', 'authorizedModules']),
  (data) => (data ? data.toJS() : [])
);

export const selectIsMainUser = (state) =>
  state.getIn(['loggedUserInfo', 'info', 'data', 'user', 'isMainUser']);

export const selectUserPackagesNames = createSelector(
  selectUserAuthorizedModules,
  (userModules) => getUserPackagesFromModules(userModules)
);

/**
 * Selected bool indicates whether user should see approve invoice information
 */
export const selectCanOnlyView = createSelector(
  selectUserAuthorizedModules,
  (authorizedModules) => authorizedModules.includes(READONLY_INVOICE)
);

/**
 * Selected bool indicates whether user is able to actually validate invoice
 * (by customer request if both rights are present, the one with the least privileges
 * takes effect)
 */
export const selectCanValidate = createSelector(
  selectUserAuthorizedModules,
  (authorizedModules) =>
    authorizedModules.includes(APPROVE_INVOICE) &&
    !authorizedModules.includes(READONLY_INVOICE)
);

/**
 * Selected bool indicates whether user should be able to prevalidate invoices
 */
export const selectCanPrevalidate = createSelector(
  selectUserAuthorizedModules,
  (authorizedModules) => authorizedModules.includes(PRE_VALIDATE_INVOICE)
);

/**
 * Selected bool indicates whether user should see pay invoice information
 */
export const selectCanPay = createSelector(
  selectUserAuthorizedModules,
  (authorizedModules) =>
    [PAY_INVOICE, APPROVE_AND_PAY_INVOICE].some((module) =>
      authorizedModules.includes(module)
    )
);

/**
 * Selected bool indicates whether user should see pay invoice information
 * and be able to pay not yet validated invoices
 */
export const selectCanValidateAndPay = createSelector(
  selectUserAuthorizedModules,
  (authorizedModules) => authorizedModules.includes(APPROVE_AND_PAY_INVOICE)
);

/**
 * For frontend page we exclude ADDITIONAL_USER role and all free modules
 */
export const selectUserAdditionalUsers = createSelector(
  [
    (state) =>
      state.getIn([
        'loggedUserInfo',
        'info',
        'data',
        'user',
        'additionalUsers',
      ]),
    (_, modulesToInclude) => modulesToInclude || [],
    selectUserCurrentCompanyDosId,
  ],
  (additionalUsers, modulesToInclude, currentCompanyId) =>
    additionalUsers
      ? additionalUsers
          .toJS()
          .filter((user) => user.companiesId.includes(currentCompanyId))
          .map(({ modules, ...restUser }) => ({
            ...restUser,
            modules,
            tableModules: modules.filter((module) => {
              const isNotExcluded = ![
                ADDITIONAL_USER,
                DASHBOARD_HR,
                PAY_INVOICE,
              ].includes(module);

              if (modulesToInclude.length === 0) {
                return isNotExcluded;
              }

              return modulesToInclude.includes(module) && isNotExcluded;
            }),
          }))
      : []
);
