import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Form, Row, Col, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Radio } from 'components/ui';
import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import { INVOICE_SEARCH_PAYMENT_STATUSES } from 'const';
import MainStyles from 'assets/styles/main.module.css';
import {
  invoicesSearchInit,
  invoicesSearchRequest,
} from 'modules/invoiceSearch/actions';
import { selectCanValidate } from 'modules/loggedUserInfo/selectors';
import { useFormik } from 'formik';
import {
  getInitialValues,
  useInvoiceType,
  useInvoicesSearchRequestParams,
} from 'modules/invoiceSearch/helpers';
import Styles from 'containers/InvoiceSearchPage/InvoiceSearch.module.css';
import { StatusInput } from './StatusInput';
import { RangedInputs } from './RangedInputs';
import { TextInputs } from './TextInputs';
import { MassActionsButtons } from './MassActionsButtons';

const { labels, lists, BUTTONS } = INVOICE_SEARCH_TRANSLATIONS;
const {
  PAID_OUTSIDE,
  NOT_VALIDATED,
  ALL,
  PREVALIDATED,
} = INVOICE_SEARCH_PAYMENT_STATUSES;

const propTypes = {
  selectedRowKeys: PropTypes.array,
  setSelectedRowKeys: PropTypes.func,
  setSelectedInvoicesKeys: PropTypes.func,
  canHaveSelections: PropTypes.bool,
  setCanHaveSelections: PropTypes.func,
};

export const SearchForm = memo(
  ({
    selectedRowKeys,
    setSelectedRowKeys,
    setSelectedInvoicesKeys,
    canHaveSelections,
    setCanHaveSelections,
  }) => {
    const dispatch = useDispatch();

    const canValidate = useSelector(selectCanValidate);

    const invoiceType = useInvoiceType();
    const getSearchParams = useInvoicesSearchRequestParams();

    useEffect(() => {
      dispatch(invoicesSearchInit());
    }, [dispatch]);

    useEffect(() => {
      const { invoiceDateCond, amountCond, ...searchParams } = getSearchParams(
        getInitialValues(invoiceType)
      );
      dispatch(invoicesSearchRequest(searchParams));
    }, [dispatch, getSearchParams, invoiceType]);

    const formik = useFormik({
      initialValues: getInitialValues(invoiceType),
      enableReinitialize: true,
      onSubmit: () => {
        setSelectedRowKeys([]);
        setSelectedInvoicesKeys([]);
        setCanHaveSelections(
          [PAID_OUTSIDE, NOT_VALIDATED, ALL, PREVALIDATED].includes(
            formik.values.paymentStatus
          )
        );

        const {
          invoiceDateCond,
          amountCond,
          ...searchParams
        } = getSearchParams(formik.values);
        dispatch(invoicesSearchRequest(searchParams));
      },
      validate: (values) => {
        const errors = {};

        if (values.invoiceDate && !values.invoiceDateInput1) {
          errors.invoiceDateInput1 = true;
        }

        if (values.invoiceDate === 'between' && !values.invoiceDateInput2) {
          errors.invoiceDateInput2 = true;
        }

        if (values.dueDate && !values.dueDateInput1) {
          errors.dueDateInput1 = true;
        }

        if (values.dueDate === 'between' && !values.dueDateInput2) {
          errors.dueDateInput2 = true;
        }

        if (values.amountWithVat && !values.amountWithVatInput1) {
          errors.amountWithVatInput1 = true;
        }

        if (values.amountWithVat === 'between' && !values.amountWithVatInput2) {
          errors.amountWithVatInput2 = true;
        }

        if (values.amount && !values.amountInput1) {
          errors.amountInput1 = true;
        }

        if (values.amount === 'between' && !values.amountInput2) {
          errors.amountInput2 = true;
        }

        return errors;
      },
    });

    useEffect(() => {
      formik.setFieldValue('invoiceType', invoiceType);

      // Do not include `formik` as a dependency here,
      // it causes the component to rerender infinitely.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceType]);

    return (
      <Form onSubmit={formik.handleSubmit}>
        <Row className={MainStyles.row}>
          <TextInputs
            onChange={formik.handleChange}
            formikValues={formik.values}
          />
          <Col span={24} className={MainStyles.col}>
            <Typography.Paragraph
              className={clsx(MainStyles.formLabel, Styles.formLabel)}
            >
              {labels.invoiceType}
            </Typography.Paragraph>
            <Form.Item className={Styles.formItem}>
              <Radio
                options={lists.invoiceTypeOptions}
                onChange={formik.handleChange}
                value={formik.values.invoiceType}
                name="invoiceType"
              />
            </Form.Item>
          </Col>
          <RangedInputs
            onChange={formik.handleChange}
            formikValues={formik.values}
            formikErrors={formik.errors}
            formikSetFieldValues={formik.setFieldValue}
          />
          <StatusInput
            onChange={formik.handleChange}
            value={formik.values.paymentStatus}
          />
        </Row>
        <div className={clsx(MainStyles.buttonWrapper, Styles.buttonWrapper)}>
          <Button outlined big onClick={formik.handleReset}>
            {BUTTONS.INIT}
          </Button>
          <div className={Styles.massActionButtonsWrapper}>
            {canHaveSelections && (
              <MassActionsButtons
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedInvoicesKeys}
                canValidate={canValidate}
              />
            )}
            <Button htmlType="submit" big>
              {BUTTONS.SEARCH}
            </Button>
          </div>
        </div>
      </Form>
    );
  }
);

SearchForm.propTypes = propTypes;
