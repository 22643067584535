import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import arrowLeft from 'assets/icons/arrow-left.svg';
import arrowRight from 'assets/icons/arrow-right.svg';
import Styles from './Banners.module.css';

const propTypes = {
  handleNext: PropTypes.func,
  handlePrev: PropTypes.func,
  wraps: PropTypes.element,
  buttonsClass: PropTypes.string,
  isShowButtons: PropTypes.boolean,
};

export const SwitchButtons = ({
  handleNext,
  handlePrev,
  wraps,
  buttonsClass,
  isShowButtons = true,
}) => (
  <div className={Styles.bannerButtonsWrapper}>
    {isShowButtons && (
      <button
        type="button"
        className={clsx(Styles.switchBannerButton, buttonsClass)}
        onClick={handlePrev}
      >
        <img src={arrowLeft} alt="arrow left" />
      </button>
    )}

    {wraps}
    {isShowButtons && (
      <button
        type="button"
        className={clsx(Styles.switchBannerButton, buttonsClass)}
        onClick={handleNext}
      >
        <img src={arrowRight} alt="arrow right" />
      </button>
    )}
  </div>
);

SwitchButtons.propTypes = propTypes;
