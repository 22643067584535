import { useCallback } from 'react';
import { LOGIC_CONDITIONS, INVOICE_TYPES } from 'const/translations';
import { omitEmptyKeys } from 'helpers/common';
import { DATE_FORMAT, INVOICE_SEARCH_PAYMENT_STATUSES, ROUTES } from 'const';
import moment from 'moment';
import { useLocation } from 'react-router';
import { PAID_INVOICE_STATUSES } from 'const/translations/InvoiceSearch';
import { selectUserCompanyId } from 'modules/loggedUserInfo/selectors';
import { useSelector } from 'react-redux';

const { ACCEPTED, REJECTED } = INVOICE_SEARCH_PAYMENT_STATUSES;

export const getInitialValues = (invoiceType) => ({
  invoiceType,
  keywords: '',
  provider: '',
  invoiceNumber: '',
  approved: null,
  paymentStatus: PAID_INVOICE_STATUSES.ALL.value,
  invoiceDate: undefined,
  invoiceDateInput1: null,
  invoiceDateInput2: null,
  amount: undefined,
  amountInput1: null,
  amountInput2: null,
  amountWithVat: undefined,
  amountWithVatInput1: null,
  amountWithVatInput2: null,
  invoiceDateCond: 'invoiceDate',
  amountCond: 'amountWithVat',
});

const mapFormValuesToSearchParams = (formValues) => {
  const logicConditionFields = [
    'invoiceDate',
    'dueDate',
    'amount',
    'amountWithVat',
  ];

  const searchParams = logicConditionFields.reduce((values, fieldName) => {
    const {
      [fieldName]: logicCondition,
      [`${fieldName}Input1`]: input1,
      [`${fieldName}Input2`]: input2,
      ...rest
    } = values;

    const isTwoValues = logicCondition === LOGIC_CONDITIONS.BETWEEN.value;

    if (
      !logicCondition ||
      Number.isNaN(input1) ||
      (isTwoValues && Number.isNaN(input2))
    ) {
      return rest;
    }

    const newValue1 =
      typeof input1 === 'number'
        ? input1
        : moment(input1, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const newValue2 =
      typeof input2 === 'number'
        ? input2
        : moment(input2, 'DD/MM/YYYY').format('YYYY-MM-DD');

    return {
      ...rest,
      [fieldName]: {
        condition: logicCondition,
        value: isTwoValues ? [newValue1, newValue2] : newValue1,
      },
    };
  }, omitEmptyKeys(formValues));

  return searchParams;
};

export const useInvoicesSearchRequestParams = () => {
  const companyDosId = useSelector(selectUserCompanyId);
  const getSearchParams = useCallback(
    (formValues) =>
      mapFormValuesToSearchParams({
        companyDosId,
        ...formValues,
      }),
    [companyDosId]
  );

  return getSearchParams;
};

export const checkIfTiff = (filePath) => /\.tiff?$/i.test(filePath);

export const transformInvoiceForStore = (documentInfo) => ({
  ...documentInfo,
  amountWithVat: documentInfo.amount + documentInfo.vat,
  key: documentInfo.id,
});

export const mapInvoicesResponseToReduxStore = (documentsArray = []) =>
  documentsArray.map(transformInvoiceForStore);

export const calculateLimit = ({
  total,
  page,
  itemsPerPage,
  downloadedAmount,
}) => Math.min(total, page * itemsPerPage) - downloadedAmount;

export const calculateOffset = ({ page, itemsPerPage }) =>
  (page - 1) * itemsPerPage;

/**
 * Helper selects invoices with payment statuses that indicate that
 * payment is in process and invoices require status updates.
 * @param invoices {Array}
 * @returns {Array} of ids
 */
export const getPendingInvoicesIds = (invoices) =>
  invoices.reduce((acc, { documentId, paymentStatus }) => {
    if (paymentStatus && ![ACCEPTED, REJECTED].includes(paymentStatus)) {
      acc.push(documentId);
    }
    return acc;
  }, []);

export const getDefaultApproveInvoicePayload = (documentId) => ({
  documentId,
  approvedDueDate: moment().format(DATE_FORMAT),
  paymentApprovedComment: '',
});

export const useInvoiceType = () => {
  const { pathname } = useLocation();

  return pathname === ROUTES.INVOICE_SEARCH
    ? INVOICE_TYPES.ALL.value
    : INVOICE_TYPES.PURCHASE.value;
};
