import { fromJS } from 'immutable';
import moment from 'moment';
import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from '../../../../const';
import {
  FETCH_COMPANY_BRANCHES_SUCCESS,
  FETCH_EMPLOYEES_ERROR,
  FETCH_EMPLOYEES_REQUEST,
  FETCH_EMPLOYEES_SUCCESS,
  RESET_EMPLOYEES_FILTERS,
  SET_DATE_RANGE,
  SET_EMPLOYEES_FILTERS,
  SET_EMPLOYEES_SEARCH,
  SET_SCHEDULE_ID,
  SET_EMPLOYEE_SCHEDULE_SUCCESS,
  DOWNLOAD_COMPANY_EMPLOYEES_PAYSLIPS,
  DOWNLOAD_COMPANY_EMPLOYEES_PAYSLIPS_SUCCESS,
  DOWNLOAD_EMPLOYEE_PAYSLIPS,
  DOWNLOAD_EMPLOYEE_PAYSLIPS_SUCCESS,
  FETCH_LAST_SYNC_TIME_SUCCESS,
  FETCH_IS_SYNCING_SUCCESS,
} from '../actions';

const initialState = fromJS({
  employeesList: [],
  companyBranches: [],
  status: STATUS_NOT_REQUESTED,
  filters: {
    establishments: '',
    natureEmployments: '',
    classifications: '',
    contracts: '',
  },
  dateRange: {
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
  },
  searchTerm: '',
  chosenScheduleId: '',
  isWaitingModalShow: false,
  isSingleWaitingModalShow: false,
  lastSyncTime: '',
  syncing: false,
});

const initialFilters = {
  filters: {
    establishments: '',
    natureEmployments: '',
    classifications: '',
    contracts: '',
  },
  dateRange: {
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
  },
  searchTerm: '',
};

export const employeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEES_REQUEST:
      return state.set('status', STATUS_LOADING);
    case FETCH_EMPLOYEES_SUCCESS: {
      const { data } = action.payload;
      return state
        .set('status', STATUS_SUCCESS)
        .set('employeesList', fromJS(data));
    }
    case FETCH_LAST_SYNC_TIME_SUCCESS: {
      const { data } = action.payload;
      return state.set('lastSyncTime', fromJS(data));
    }
    case FETCH_IS_SYNCING_SUCCESS: {
      const { data } = action.payload;
      return state.set('syncing', fromJS(data));
    }
    case FETCH_EMPLOYEES_ERROR:
      return state.set('status', STATUS_ERROR);
    case SET_EMPLOYEES_FILTERS:
      return state.set('filters', action.payload);
    case RESET_EMPLOYEES_FILTERS:
      return state
        .set('filters', initialFilters.filters)
        .set('searchTerm', initialFilters.searchTerm);
    case SET_EMPLOYEES_SEARCH:
      return state.set('searchTerm', action.payload);
    case FETCH_COMPANY_BRANCHES_SUCCESS:
      return state.set('companyBranches', action.payload);
    case SET_DATE_RANGE:
      return state.set('dateRange', action.payload);
    case SET_SCHEDULE_ID:
      return state.set('chosenScheduleId', action.payload);
    case SET_EMPLOYEE_SCHEDULE_SUCCESS: {
      const {
        newSchedule: {
          data: { employeeSchedule },
        },
      } = action.payload;

      return state
        .updateIn(['employeesList'], (employees) => {
          const index = employees.findIndex(
            (employee) =>
              employeeSchedule.employeeId === employee.get('employeeid')
          );

          return employees.mergeIn([index], fromJS(employeeSchedule));
        })
        .set('chosenScheduleId', action.payload);
    }

    case DOWNLOAD_COMPANY_EMPLOYEES_PAYSLIPS:
      return state.set('isWaitingModalShow', true);
    case DOWNLOAD_COMPANY_EMPLOYEES_PAYSLIPS_SUCCESS:
      return state.set('isWaitingModalShow', false);

    case DOWNLOAD_EMPLOYEE_PAYSLIPS:
      return state.set('isSingleWaitingModalShow', true);
    case DOWNLOAD_EMPLOYEE_PAYSLIPS_SUCCESS:
      return state.set('isSingleWaitingModalShow', false);

    default:
      return state;
  }
};
