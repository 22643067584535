import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { setRewardsFamily } from 'modules/SalaryModule/Rewards/actions';
import { RewardsToolbar } from '../../../components/SalaryComponents/RewardsToolbar';
import { selectUserCompanyId } from '../../../modules/loggedUserInfo/selectors';
import { fetchCompanyDetailRequest } from '../../../modules/SalaryModule/CompanySettings/actions';
import { upsertEmployeeReward } from '../../../modules/SalaryModule/RewardsTable/actions';
import { RewardsTable } from './RewardsTable';
import Styles from './RewardsPage.module.css';

export const RewardsPage = memo(() => {
  const dispatch = useDispatch();

  const companyId = useSelector(selectUserCompanyId);

  const [currentPage, setCurrentPage] = useState(1);
  const [activeFamily, setActiveFamily] = useState('');
  const [selectedBranch, setSelectedBranch] = useState('');
  const [employeeRewardData, setEmployeeRewardData] = useState(null);
  const [employeeIdXlsx, setEmployeeIdXlsx] = useState(null);
  const [activeMonth, setActiveMonth] = useState(moment());

  useScrollToTop();

  useEffect(
    () => () => {
      dispatch(setRewardsFamily(null));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetchCompanyDetailRequest({ companyId }));
  }, [dispatch, companyId]);

  useEffect(() => {
    if (employeeRewardData && setEmployeeRewardData) {
      const { value, companyRewardId, employeeId } = employeeRewardData;
      dispatch(
        upsertEmployeeReward({
          companyId,
          data: {
            value,
            companyRewardId,
            employeeId,
            date: {
              year: activeMonth.year(),
              month: activeMonth.month() + 1,
            },
          },
        })
      );
      setEmployeeRewardData(null);
    }
  }, [companyId, dispatch, employeeRewardData, activeMonth]);

  return (
    <div className={Styles.container}>
      <RewardsToolbar
        companyId={companyId}
        activeMonth={activeMonth}
        setActiveMonth={setActiveMonth}
        employeeIdXlsx={employeeIdXlsx}
        setEmployeeIdXlsx={setEmployeeIdXlsx}
        activeFamily={activeFamily}
        selectedBranch={selectedBranch}
        setActiveFamily={setActiveFamily}
        setSelectedBranch={setSelectedBranch}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <RewardsTable
        setEmployeeIdXlsx={setEmployeeIdXlsx}
        setEmployeeRewardData={setEmployeeRewardData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        selectedBranch={selectedBranch}
      />
    </div>
  );
});
