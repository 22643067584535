import React, { useCallback, useEffect, useState } from 'react';
import { Icon, Row, Tooltip, Layout, Typography } from 'antd';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useSimpleMedia } from 'hooks/useMedia';
import MainStyles from 'assets/styles/main.module.css';
import { COMPANY_SETTINGS } from 'const/translations/SalaryCompanySettings';
import { selectUserCurrentCompany } from 'modules/loggedUserInfo/selectors';
import { FORGOTTEN_PASSWORD_TRANSLATIONS } from 'const/translations';
import {
  setActiveBranch,
  createBranch,
  updateBranch,
  deleteBranch,
} from 'modules/SalaryModule/CompanySettings/actions';
import {
  selectActiveBranchData,
  selectBranches,
  selectBranchesOptions,
} from 'modules/SalaryModule/CompanySettings/selectors';
import { Checkbox, CustomSelect, Input, Radio, Button } from 'components/ui';
import Styles from './CompanySettingsForm.module.css';
import { FormItem } from './FormItem';

const { Text } = Typography;
const { Content } = Layout;

const SCREEN_WIDTH_BREAKPOINT_MEDIA = `(max-width: ${COMPANY_SETTINGS.SCREEN_WIDTH_BREAKPOINT_VALUE}px)`;
const { BRANCH_FORM } = COMPANY_SETTINGS;
const { ERRORS } = FORGOTTEN_PASSWORD_TRANSLATIONS;

export const CompanySettingsForm = () => {
  const dispatch = useDispatch();
  const {
    name: companyName,
    id: companyId,
    managedXpb,
    managedSilae,
  } = useSelector(selectUserCurrentCompany);
  const branches = useSelector(selectBranches);
  const options = useSelector(selectBranchesOptions);
  const activeBranchData = useSelector(selectActiveBranchData);
  const isMobileVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);

  const [isNewBranch, setIsNewBranch] = useState(false);

  const addBranch = (e) => {
    e.preventDefault();
    setIsNewBranch(true);
  };

  useEffect(() => {
    if (activeBranchData) {
      setIsNewBranch(false);
    } else {
      setIsNewBranch(true);
    }
  }, [activeBranchData]);

  const handleBranchChange = useCallback(
    (_, branchValue) => {
      const activeBranch = branches.find(({ siret }) => siret === branchValue);
      dispatch(setActiveBranch(activeBranch));
      setIsNewBranch(false);
    },
    [branches, dispatch]
  );

  const handleDeleteChange = useCallback(
    (value) => {
      if (companyId) {
        dispatch(
          deleteBranch({
            companyId,
            siret: value,
          })
        );
      }
    },
    [dispatch, companyId]
  );

  const handleSubmitForm = useCallback(
    ({ siren, name, siretSelect, siret, ...restValues }, setSubmitting) => {
      if (companyId) {
        setSubmitting(true);
        if (isNewBranch) {
          dispatch(
            createBranch({
              companyId,
              data: { siret, ...restValues },
              setSubmitting,
            })
          );
          return;
        }

        dispatch(
          updateBranch({
            companyId,
            siret,
            data: restValues,
            setSubmitting,
          })
        );
      }
    },
    [companyId, dispatch, isNewBranch]
  );

  const tooltipContent = (
    <>
      <Text className={Styles.tooltipText}>{BRANCH_FORM.TOOLTIP_TEXT1}</Text>
      <Text className={Styles.tooltipText}>{BRANCH_FORM.TOOLTIP_TEXT2}</Text>
    </>
  );

  const isManagedByXPB = managedXpb;
  const isManagedBySilae = managedSilae;

  const initialValues = {
    siren: activeBranchData?.siret?.substr(0, 9) || '',
    name: companyName || '',
    siretSelect: activeBranchData?.siret || '',
    siret: activeBranchData?.siret || '',
    daycount:
      activeBranchData?.daycount || BRANCH_FORM.PAY_METHOD_OPTIONS[0].value,
    waynumber1: activeBranchData?.waynumber1 || '',
    wayname: activeBranchData?.wayname || '',
    wayname2: activeBranchData?.wayname2 || '',
    postcode: activeBranchData?.postcode || '',
    city: activeBranchData?.city || '',
    foreignadress: activeBranchData?.foreignadress || false,
    countrycode: activeBranchData?.countrycode || '',
    distributioncode: activeBranchData?.distributioncode || '',
  };

  const newBranchInitialValues = {
    daycount: BRANCH_FORM.PAY_METHOD_OPTIONS[0].value,
    foreignadress: false,
    countrycode: '',
    distributioncode: '',
    wayname2: '',
  };

  return (
    <Content>
      <Formik
        initialValues={
          (!isNewBranch && initialValues) || newBranchInitialValues
        }
        validateOnMount={false}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          handleSubmitForm(values, setSubmitting);
        }}
        validate={(values) => {
          const errors = {};
          if (
            JSON.stringify(newBranchInitialValues) === JSON.stringify(values)
          ) {
            return {};
          }
          if (
            !values.waynumber1 ||
            String(Number(values.waynumber1)) === 'NaN'
          ) {
            errors.waynumber1 = ERRORS.FIELD_REQUIRED;
          }
          if (!values.wayname) {
            errors.wayname = ERRORS.FIELD_REQUIRED;
          }
          if (
            !values.siret ||
            String(Number(values.siret)) === 'NaN' ||
            values.siret.length !== 14
          ) {
            errors.siret = ERRORS.FIELD_REQUIRED;
          }
          if (
            !values.postcode ||
            String(Number(values.postcode)) === 'NaN' ||
            values.postcode.includes(' ')
          ) {
            errors.postcode = ERRORS.FIELD_REQUIRED;
          }
          if (!values.city) {
            errors.city = ERRORS.FIELD_REQUIRED;
          }
          if (values.foreignadress && !values.countrycode) {
            errors.countrycode = ERRORS.FIELD_REQUIRED;
          }

          return errors;
        }}
      >
        {({
          values,
          errors,
          dirty,
          handleSubmit,
          handleChange,
          resetForm,
          isSubmitting,
          setFieldValue,
        }) => {
          const branchNewOptions = [
            {
              value: values.siret,
              label:
                (values.siret &&
                  values.waynumber1 &&
                  values.wayname &&
                  `${values.siret} - ${values.waynumber1}, ${values.wayname}`) ||
                (values.siret &&
                  values.waynumber1 &&
                  `${values.siret} - ${values.waynumber1}`) ||
                values?.siret ||
                BRANCH_FORM.NEW_BRANCH,
              isNew: true,
            },
            ...options,
          ];

          if (isNewBranch && values.siren !== values.siret?.substr(0, 9)) {
            setFieldValue('siren', values.siret?.substr(0, 9));
          }

          if (
            !values.foreignadress &&
            (values.countrycode || values.distributioncode)
          ) {
            setFieldValue('countrycode', undefined);
            setFieldValue('distributioncode', undefined);
          }

          return (
            <form onSubmit={handleSubmit}>
              <Row className={clsx(MainStyles.row, Styles.formMainRow)}>
                <FormItem label={BRANCH_FORM.SIREN_NUMBER}>
                  <Input
                    size="large"
                    className={clsx(Styles.formInput, Styles.formInputFull)}
                    disabled
                    onChange={COMPANY_SETTINGS.NOOP_FUNCTION}
                    name="siren"
                    value={values.siren || ''}
                  />
                </FormItem>

                <FormItem label={BRANCH_FORM.COMPANY_NAME}>
                  <Input
                    size="large"
                    className={clsx(Styles.formInput, Styles.formInputFull)}
                    name="name"
                    value={values.name || ''}
                    disabled
                    onChange={COMPANY_SETTINGS.NOOP_FUNCTION}
                  />
                </FormItem>
              </Row>

              <Row className={clsx(MainStyles.row, Styles.formMainRow)}>
                <FormItem label={BRANCH_FORM.CHOOSE_BRANCH}>
                  <CustomSelect
                    size="large"
                    options={isNewBranch ? branchNewOptions : options}
                    name="siretSelect"
                    value={values.siret}
                    onChange={handleBranchChange}
                    placeholder={
                      isNewBranch
                        ? BRANCH_FORM.NEW_BRANCH
                        : BRANCH_FORM.CHOOSE_BRANCH
                    }
                    className={Styles.formSelect}
                    dropdownStyle={{ minWidth: 'min-content' }}
                    hasTooltip
                    onDelete={handleDeleteChange}
                    deletable={!isManagedByXPB}
                  />
                  {!isManagedByXPB && !isManagedBySilae && (
                    <Button
                      variant="transparent"
                      className={Styles.newBranch}
                      onClick={addBranch}
                    >
                      <Icon type="plus" /> {BRANCH_FORM.NEW_BRANCH}
                    </Button>
                  )}
                </FormItem>
              </Row>

              <Row className={clsx(MainStyles.row, Styles.formMainRow)}>
                <FormItem label={BRANCH_FORM.NUMBER}>
                  <Input
                    size="large"
                    disabled={isManagedByXPB || !isNewBranch}
                    name="siret"
                    onChange={handleChange}
                    value={values.siret || ''}
                    className={Styles.formInput}
                    error={errors.siret}
                    maxLength={14}
                  />
                </FormItem>

                <FormItem
                  label={BRANCH_FORM.PAY_METHOD}
                  tooltip={
                    (isMobileVersion && (
                      <Tooltip
                        placement="topLeft"
                        arrowPointAtCenter
                        overlayClassName={Styles.tooltipOverlay}
                        title={tooltipContent}
                      >
                        <Icon className={Styles.icon} type="info-circle" />
                      </Tooltip>
                    )) ||
                    null
                  }
                >
                  <div className={Styles.formRow}>
                    <Radio
                      className={Styles.radioGroup}
                      options={BRANCH_FORM.PAY_METHOD_OPTIONS}
                      value={values.daycount || ''}
                      disabled={isManagedByXPB}
                      onChange={handleChange}
                      name="daycount"
                    />
                    {!isMobileVersion && (
                      <Tooltip
                        placement="topLeft"
                        arrowPointAtCenter
                        overlayClassName={Styles.tooltipOverlay}
                        title={tooltipContent}
                      >
                        <Icon className={Styles.icon} type="info-circle" />
                      </Tooltip>
                    )}
                  </div>
                </FormItem>

                <FormItem label={BRANCH_FORM.CHANNEL_NUMBER}>
                  <div className={clsx(Styles.formRowDouble, Styles.formRow)}>
                    <Input
                      size="large"
                      className={Styles.formInput}
                      value={values.waynumber1 || ''}
                      onChange={handleChange}
                      name="waynumber1"
                      disabled={isManagedByXPB}
                      error={errors.waynumber1}
                    />
                  </div>
                </FormItem>

                <FormItem label={BRANCH_FORM.ROAD_NAME}>
                  <Input
                    size="large"
                    className={clsx(Styles.formInput, Styles.formInputBig)}
                    value={values.wayname || ''}
                    disabled={isManagedByXPB}
                    name="wayname"
                    onChange={handleChange}
                    error={errors.wayname}
                  />
                </FormItem>

                <FormItem label={BRANCH_FORM.ADDITIONAL_ADDRESS}>
                  <Input
                    size="large"
                    className={clsx(Styles.formInput, Styles.formInputBig)}
                    value={values.wayname2 || ''}
                    disabled={isManagedByXPB}
                    name="wayname2"
                    onChange={handleChange}
                    error={errors.wayname2}
                  />
                </FormItem>

                <FormItem label={BRANCH_FORM.POSTAL_CODE}>
                  <Input
                    size="large"
                    className={Styles.formInput}
                    value={values.postcode || ''}
                    disabled={isManagedByXPB}
                    name="postcode"
                    onChange={handleChange}
                    error={errors.postcode}
                  />
                </FormItem>

                <FormItem label={BRANCH_FORM.CITY}>
                  <Input
                    size="large"
                    className={Styles.formInput}
                    value={values.city || ''}
                    disabled={isManagedByXPB}
                    name="city"
                    onChange={handleChange}
                    error={errors.city}
                  />
                </FormItem>

                <FormItem label={BRANCH_FORM.FOREIGN_ADDRESS}>
                  <Checkbox
                    name="foreignadress"
                    disabled={isManagedByXPB}
                    checked={values.foreignadress}
                    onChange={handleChange}
                  />
                </FormItem>

                <FormItem label={BRANCH_FORM.COUNTRY_CODE}>
                  <Input
                    size="large"
                    className={Styles.formInput}
                    value={values.countrycode || ''}
                    disabled={isManagedByXPB || !values.foreignadress}
                    name="countrycode"
                    onChange={handleChange}
                    error={errors.countrycode}
                  />
                </FormItem>

                <FormItem label={BRANCH_FORM.DISTRIBUTION_CODE}>
                  <Input
                    size="large"
                    className={Styles.formInput}
                    value={values.distributioncode || ''}
                    disabled={isManagedByXPB || !values.foreignadress}
                    name="distributioncode"
                    onChange={handleChange}
                    error={errors.distributioncode}
                  />
                </FormItem>

                {!isManagedByXPB && (
                  <div className={Styles.buttonWrapper}>
                    <Button
                      variant="success"
                      type="submit"
                      disabled={!dirty || isSubmitting}
                    >
                      {BRANCH_FORM.CONFIRM_BUTTON}
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={resetForm}
                      disabled={!dirty || isSubmitting}
                    >
                      {BRANCH_FORM.CANCEL_BUTTON}
                    </Button>
                  </div>
                )}
              </Row>
            </form>
          );
        }}
      </Formik>
    </Content>
  );
};
