import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Icon, Spin } from 'antd';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { PageTitle } from 'components/SalaryComponents/PageTitle';
import { EMPLOYEE_HEADER } from 'const/translations/SalaryEmployeePage';
import {
  SALARY_HEADER_LABELS,
  getSilaeRefreshEmployeesTranslation,
} from 'const/translations';
import { CustomRangePicker } from 'components/ui/CustomRangePicker';
import { CalendarMiniIcon } from 'components/ui/CalendarMiniIcon';
import {
  downloadEmployeesTableXlsx,
  downloadCompanyEmployeesPayslips,
  refreshSilaeEmployees,
} from 'modules/SalaryModule/Employees/actions';
import { compareByLabel } from 'helpers/common';
import { ROUTES, ISO_DATE_FORMAT } from 'const';
import {
  selectCompanyBranchesOptions,
  selectIsWaitingModalShow,
  selectIsSingleWaitingModalShow,
} from 'modules/SalaryModule/Employees/selectors';
import { getEmployeeTableCsvCommonFilter } from 'modules/SalaryModule/Employees/utils';
import iconFilterList from 'assets/icons/filter-list.svg';
import { MobileFilters } from 'containers/Salary/EmployeesPage/MobileFilters';
import { useSimpleMedia } from 'hooks/useMedia';
import { Button, CustomSelect, Input } from 'components/ui';
import refreshIcon from 'assets/icons/refresh-icon.svg';
import Styles from './FiltersToolbar.module.css';

const SCREEN_WIDTH_BREAKPOINT_VALUE = 796;
const SCREEN_WIDTH_BREAKPOINT_MEDIA = `(max-width: ${SCREEN_WIDTH_BREAKPOINT_VALUE}px)`;
const RANGE_PICKER_FORMAT = 'DD/MM/YYYY';

const {
  BUTTONS_LABELS,
  FILTER_PLACEHOLDERS,
  WAITING_MODAL,
  SINGLE_WAITING_MODAL,
} = EMPLOYEE_HEADER;

const propTypes = {
  companyId: PropTypes.string,
  natureEmploymentsOptions: PropTypes.array,
  classificationsOptions: PropTypes.array,
  contractsOptions: PropTypes.array,
  filters: PropTypes.object,
  searchTerm: PropTypes.string,
  dateRange: PropTypes.object,
  hasFilters: PropTypes.bool,
  onSelectChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onRangeChange: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  managedSilae: PropTypes.bool,
  companyName: PropTypes.string,
  lastSyncTime: PropTypes.string,
  isSyncing: PropTypes.bool,
};

export const FiltersToolbar = memo(
  ({
    natureEmploymentsOptions,
    classificationsOptions,
    contractsOptions,
    filters,
    searchTerm,
    dateRange,
    hasFilters,
    onSelectChange,
    onSearchChange,
    onSubmit,
    onRangeChange,
    resetSearch,
    companyId,
    managedSilae,
    companyName,
    lastSyncTime,
    isSyncing,
  }) => {
    const [isModalVisible, setModalVisibility] = useState(false);
    const [isInputVisible, setInputVisibility] = useState(false);
    const isMobileVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);
    const searchInputRef = useRef();
    const branchesOptions = useSelector(selectCompanyBranchesOptions);
    const dispatch = useDispatch();

    const sortedNatureEmploymentsOptions = natureEmploymentsOptions.sort(
      compareByLabel
    );
    const sortedClassificationsOptions = classificationsOptions.sort(
      compareByLabel
    );
    const sortedContractsOptions = contractsOptions.sort(compareByLabel);

    useEffect(() => {
      if (searchInputRef && searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, [searchInputRef, isInputVisible]);

    useEffect(() => {
      if (branchesOptions.length === 1) {
        onSelectChange('establishments', branchesOptions[0].value || '');
      }
    }, [branchesOptions, onSelectChange]);

    const openModal = useCallback(() => {
      setModalVisibility(true);
    }, []);

    const closeModal = useCallback(() => {
      setModalVisibility(false);
    }, []);

    const toggleInput = () => {
      if (isInputVisible) {
        resetSearch();
      }
      setInputVisibility(!isInputVisible);
    };

    const handleSubmit = useCallback(
      (values) => {
        onSubmit(values);
        setModalVisibility(false);
      },
      [onSubmit]
    );

    const downloadTableXlsxHandler = useCallback(() => {
      dispatch(
        downloadEmployeesTableXlsx({
          companyId,
          filter: getEmployeeTableCsvCommonFilter(
            filters,
            searchTerm,
            dateRange
          ),
        })
      );
    }, [filters, searchTerm, dateRange, dispatch, companyId]);

    const downloadCompanyEmployeesPayslipsHandler = useCallback(() => {
      const { startDate, endDate } = dateRange;
      dispatch(
        downloadCompanyEmployeesPayslips({
          companyId,
          startDate: startDate.format(ISO_DATE_FORMAT),
          endDate: endDate.format(ISO_DATE_FORMAT),
          companyName,
        })
      );
    }, [dateRange, dispatch, companyId, companyName]);

    const refreshSilaeEmployee = useCallback(() => {
      if (!isSyncing) {
        dispatch(refreshSilaeEmployees(companyId));
      }
    }, [companyId, dispatch, isSyncing]);

    return (
      <>
        <div className={Styles.wrapper}>
          <div className={Styles.headerRow}>
            <PageTitle>{SALARY_HEADER_LABELS.EMPLOYEES}</PageTitle>
            {companyId === 'X-TEST-API' && managedSilae && lastSyncTime && (
              <div className={Styles.refreshWrapper}>
                {getSilaeRefreshEmployeesTranslation(lastSyncTime)}
                <div
                  className={Styles.refreshIconWrapper}
                  onClick={refreshSilaeEmployee}
                >
                  <img
                    className={cn(Styles.refreshIcon, {
                      [Styles.refreshIconLoading]: isSyncing,
                    })}
                    src={refreshIcon}
                    alt="field error"
                  />
                </div>
              </div>
            )}

            <WaitingModal />
            <SingleWaitingModal />
            <div className={Styles.downloadIconWrapper}>
              {managedSilae && (
                <Button
                  sizeAuto
                  variant="transparent"
                  className={Styles.downloadButton}
                  onClick={downloadCompanyEmployeesPayslipsHandler}
                >
                  <Icon type="download" className={Styles.downloadIcon} />
                  {BUTTONS_LABELS.DOWNLOAD_PAYSLIP_SHORT}
                </Button>
              )}
              {!managedSilae && (
                <Button
                  sizeAuto
                  variant="transparent"
                  className={Styles.downloadButton}
                  onClick={downloadTableXlsxHandler}
                >
                  <Icon type="download" className={Styles.downloadIcon} />
                  {BUTTONS_LABELS.DOWNLOAD}
                </Button>
              )}
            </div>
            <NavLink
              to={ROUTES.EMPLOYEE_REGISTER_TITLE}
              className={Styles.newButtonMobile}
            >
              <Button sizeAuto variant="secondary">
                {BUTTONS_LABELS.NEW_EMPLOYEE}
              </Button>
            </NavLink>
            <div className={Styles.buttonsWrapper}>
              {managedSilae && (
                <Button
                  sizeAuto
                  variant="transparent"
                  className={Styles.downloadButton}
                  onClick={downloadCompanyEmployeesPayslipsHandler}
                >
                  <Icon type="download" className={Styles.downloadIcon} />
                  {BUTTONS_LABELS.DOWNLOAD_PAYSLIP}
                </Button>
              )}

              <NavLink to={ROUTES.EMPLOYEE_REGISTER_TITLE}>
                <Button sizeAuto className={Styles.newButton}>
                  {BUTTONS_LABELS.NEW_EMPLOYEE}
                </Button>
              </NavLink>
            </div>
          </div>
          {isMobileVersion && isInputVisible && (
            <Input
              ref={searchInputRef}
              placeholder={FILTER_PLACEHOLDERS.SEARCH_BY_NAME}
              prefix={
                <Icon
                  size="large"
                  type="search"
                  className={Styles.searchIconPrefix}
                />
              }
              className={Styles.searchInputMobile}
              onChange={onSearchChange}
              value={searchTerm}
              allowClear
            />
          )}
          <div className={Styles.filtersRow}>
            <CustomRangePicker
              suffixIcon={
                <CalendarMiniIcon style={{ color: 'var(--limeGreen)' }} />
              }
              size="large"
              onChange={onRangeChange}
              allowClear={false}
              format={RANGE_PICKER_FORMAT}
              value={[dateRange.startDate, dateRange.endDate]}
              dateDifferenceValue="year"
            />

            <div className={Styles.iconsWrapper}>
              <div className={Styles.filterIconWrapper} onClick={openModal}>
                {hasFilters && <div className={Styles.filterIconBadge}></div>}
                <img src={iconFilterList} alt="icon-filter-list" />
              </div>
              <div className={Styles.searchIconWrapper} onClick={toggleInput}>
                <Icon type="search" className={Styles.searchIcon} />
              </div>
            </div>
            <div className={Styles.row}>
              <CustomSelect
                size="large"
                name="establishments"
                className={Styles.select}
                options={branchesOptions}
                onChange={onSelectChange}
                value={filters.establishments}
                dropdownStyle={{ minWidth: 'min-content' }}
                placeholder={FILTER_PLACEHOLDERS.ESTABLISHMENT}
                allowClear
              />
              <CustomSelect
                size="large"
                name="natureEmployments"
                className={Styles.select}
                options={sortedNatureEmploymentsOptions}
                onChange={onSelectChange}
                value={filters.natureEmployments}
                dropdownStyle={{ minWidth: 'min-content' }}
                placeholder={FILTER_PLACEHOLDERS.NATURE_EMPLOYMENT}
                allowClear
              />
              <CustomSelect
                size="large"
                name="classifications"
                className={Styles.select}
                options={sortedClassificationsOptions}
                onChange={onSelectChange}
                value={filters.classifications}
                placeholder={FILTER_PLACEHOLDERS.CLASSIFICATION}
                allowClear
              />
              <CustomSelect
                size="large"
                name="contracts"
                className={Styles.select}
                options={sortedContractsOptions}
                onChange={onSelectChange}
                value={filters.contracts}
                placeholder={FILTER_PLACEHOLDERS.CONTRACT}
                allowClear
              />
              <Input
                size="large"
                allowClear
                placeholder={FILTER_PLACEHOLDERS.SEARCH_BY_NAME}
                prefix={
                  <Icon type="search" className={Styles.searchIconPrefix} />
                }
                onChange={onSearchChange}
                value={searchTerm}
                className={Styles.searchInput}
              />
            </div>
          </div>
        </div>
        {isMobileVersion && isModalVisible && (
          <MobileFilters
            isVisible={isModalVisible}
            onClose={closeModal}
            onSubmit={handleSubmit}
            initialValues={filters}
          />
        )}
      </>
    );
  }
);

FiltersToolbar.propTypes = propTypes;

const WaitingModal = () => {
  const isWaitingModalShow = useSelector(selectIsWaitingModalShow);

  return (
    <Modal
      centered
      destroyOnClose
      visible={isWaitingModalShow}
      footer={false}
      className={Styles.modal}
      closable={false}
      title={
        <div className={Styles.modalTitleWrapper}>
          {WAITING_MODAL.DOWNLOAD} <Spin />
        </div>
      }
    >
      {WAITING_MODAL.TEXT}
    </Modal>
  );
};

const SingleWaitingModal = () => {
  const isSingleWaitingModalShow = useSelector(selectIsSingleWaitingModalShow);

  return (
    <Modal
      centered
      destroyOnClose
      visible={isSingleWaitingModalShow}
      footer={false}
      className={Styles.modal}
      closable={false}
      title={
        <div className={Styles.modalTitleWrapper}>
          {SINGLE_WAITING_MODAL.DOWNLOAD} <Spin />
        </div>
      }
    >
      {SINGLE_WAITING_MODAL.TEXT}
    </Modal>
  );
};
