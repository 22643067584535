import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Form, Col, Typography } from 'antd';
import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import MainStyles from 'assets/styles/main.module.css';
import { SelectAdapted } from 'components/adaptors/Select';
import { Range } from 'components/ui/Range';
import Styles from 'containers/InvoiceSearchPage/InvoiceSearch.module.css';
import { PairedInput } from './PairedInput';

const { labels, lists, placeholders } = INVOICE_SEARCH_TRANSLATIONS;

const propTypes = {
  onChange: PropTypes.func.isRequired,
  formikValues: PropTypes.object.isRequired,
  formikErrors: PropTypes.object,
  formikSetFieldValues: PropTypes.func,
};

const getFieldNameToReset = (name) => {
  const firstName =
    (name === 'invoiceDate' && 'dueDate') ||
    (name === 'dueDate' && 'invoiceDate') ||
    (name === 'amount' && 'amountWithVat') ||
    (name === 'amountWithVat' && 'amount');

  return [firstName, `${firstName}Input1`, `${firstName}Input2`];
};

export const RangedInputs = memo(
  ({ onChange, formikValues, formikErrors, formikSetFieldValues }) =>
    Object.entries(labels)
      .slice(4, 6)
      .map(([name, label]) => {
        const options =
          (name === 'invoiceDateCond' && lists.invoiceDates) ||
          (name === 'amountCond' && lists.amounts);

        const onChangeSelect = (event) => {
          onChange(event);

          formikSetFieldValues(
            getFieldNameToReset(event.target.value)[0],
            undefined
          );
          formikSetFieldValues(
            getFieldNameToReset(event.target.value)[1],
            undefined
          );
          formikSetFieldValues(
            getFieldNameToReset(event.target.value)[2],
            undefined
          );
        };

        return (
          <Col span={24} className={MainStyles.col} key={name}>
            <Typography.Paragraph
              className={clsx(MainStyles.formLabel, Styles.formLabel)}
            >
              {label}
            </Typography.Paragraph>
            <span className={Styles.rowWrapInputs}>
              <Form.Item className={Styles.formItem}>
                <SelectAdapted
                  placeholder={placeholders[name]}
                  options={options}
                  onChange={onChangeSelect}
                  value={formikValues[name]}
                  name={name}
                />
              </Form.Item>
              <div className={Styles.rangeWrapper}>
                {formikValues[name] && (
                  <span className={Styles.rowInputs}>
                    <Form.Item className={Styles.formItem}>
                      <SelectAdapted
                        placeholder={placeholders[name]}
                        options={lists.searchRangeOptions}
                        onChange={onChange}
                        value={formikValues[formikValues[name]]}
                        name={formikValues[name]}
                      />
                    </Form.Item>
                    <div className={Styles.rangeWrapper}>
                      <Range
                        componentFrom={
                          <PairedInput
                            order="first"
                            name={formikValues[name]}
                            onChange={onChange}
                            formikValues={formikValues}
                            formikErrors={formikErrors}
                          />
                        }
                        componentTo={
                          <PairedInput
                            order="second"
                            name={formikValues[name]}
                            onChange={onChange}
                            formikValues={formikValues}
                            formikErrors={formikErrors}
                          />
                        }
                      />
                    </div>
                  </span>
                )}
              </div>
            </span>
          </Col>
        );
      })
);

RangedInputs.propTypes = propTypes;
