import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Typography, Icon } from 'antd';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCalendarAbsencesStatus,
  selectCalendarAbsences,
  selectCalendarDateFilter,
  selectCalendarEmployees,
  selectCalendarWithAbsencesOnly,
  selectDatesArrayForCurrentMonth,
  selectDatesArrayForCurrentYear,
  selectEmployeesWithAbsencesOnly,
  selectFilteredCalendarEmployees,
  selectYearCalendarEmployee,
  selectCalendarYear,
  selectMonthYearToggleState,
  selectCalendarEmployeeState,
  selectCalendarBranchFilter,
} from 'modules/SalaryModule/Calendar/selectors';
import { fetchEmployeesRequest } from 'modules/SalaryModule/Employees/actions';
import { selectTableDefaultPage } from 'modules/SalaryModule/Vacations/selectors';
import {
  setMonthYearToggleValue,
  setCalendarEmployeeValue,
  getCalendarAbsencesRequest,
  setCalendarDatePickerValue,
  setCalendarWithAbsencesOnly,
  downloadAbsences,
} from 'modules/SalaryModule/Calendar/actions';
import { useHolidays } from 'modules/SalaryModule/Calendar/utils';
import {
  formatDatesToFilter,
  formatEmployeeToLeaveModal,
  mapAbsencesToDates,
} from 'modules/SalaryModule/Calendar/helpers';
import { SALARY_CALENDAR_TRANSLATIONS } from 'const/translations/SalaryCalendar';
import { selectUserCurrentCompany } from 'modules/loggedUserInfo/selectors';
import { useSimpleMedia } from 'hooks/useMedia';
import { useGetCalendarMonthViewColumn } from 'hooks/useGetCalendarMonthViewColumn';
import { useGetCalendarYearViewColumn } from 'hooks/useGetCalendarYearViewColumn';

import { STATUS_LOADING, STATUS_NOT_REQUESTED } from 'const';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import { Spinner } from 'components/Spinner';
import { CalendarFilters } from 'components/SalaryComponents/Calendar/CalendarFilters';
import { getScreenWidthBreakpointMedia } from 'utils';
import {
  setEditLeaveModalData,
  setLeaveModalMainData,
  toggleVacationModalVisibility,
  downloadAbsenceAttachmentWithoutModal,
} from 'modules/SalaryModule/Vacations/actions';
import {
  getIsoFormatFromDateWithSlashes,
  getTablePaginationRange,
} from 'helpers/salaryHelpers/calendar';
import { selectEmployeesStatus } from 'modules/SalaryModule/Employees/selectors';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { Button } from 'components/ui';
// TODO: Move table selection styles from ResultsTable to main styles
import ResultsTableStyles from 'components/InvoiceSearch/SearchResultsTable/SearchResultsTable.module.css';
import { SendInfoButton } from 'components/SalaryComponents/SendInfoButton';
import Styles from './CalendarPage.module.css';

const SCREEN_WIDTH_BREAKPOINT_MEDIA = getScreenWidthBreakpointMedia(650);

const {
  CALENDAR,
  USERS_NOT_FOUND,
  DOWNLOAD,
  YEAR_VIEW_CALENADR_NO_DATA,
} = SALARY_CALENDAR_TRANSLATIONS;

const { YEAR, MONTH } = HOMEPAGE_FORM_TRANSLATIONS.YEAR_MONTH_SWITCH;

export const CalendarPage = React.memo(() => {
  const dispatch = useDispatch();
  const isMobileVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);
  const unfilteredEmployees = useSelector(selectCalendarEmployees);
  const filteredEmployees = useSelector(selectFilteredCalendarEmployees);
  const yearCalendarEmployee = useSelector(selectYearCalendarEmployee);
  const calendarAbsences = useSelector(selectCalendarAbsences);
  const branchFilter = useSelector(selectCalendarBranchFilter);
  const employeesWithAbsencesOnly = useSelector(
    selectEmployeesWithAbsencesOnly
  );
  const defaultPage = useSelector(selectTableDefaultPage);

  const { id: companyId, managedSilae, payEnabled } = useSelector(
    selectUserCurrentCompany
  );

  useScrollToTop();

  const monthYearToggle = useSelector(selectMonthYearToggleState);
  const setMonthYearToggle = useCallback(
    (value) => {
      dispatch(setMonthYearToggleValue({ value }));
    },
    [dispatch]
  );

  const calendarEmployee = useSelector(selectCalendarEmployeeState);
  const setCalendarEmployee = useCallback(
    (name, value) => {
      dispatch(setCalendarEmployeeValue({ value }));
    },
    [dispatch]
  );

  const withAbsencesOnly = useSelector(selectCalendarWithAbsencesOnly);
  const setWithAbsencesOnly = useCallback(
    (value) => {
      dispatch(setCalendarWithAbsencesOnly({ value }));
    },
    [dispatch]
  );

  const monthFilter = useSelector(selectCalendarDateFilter);
  const setMonthFilter = useCallback(
    (date) => {
      dispatch(setCalendarDatePickerValue({ date }));
    },
    [dispatch]
  );

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (companyId === 'X-TEST-API' && managedSilae && payEnabled) {
      dispatch(
        fetchEmployeesRequest({
          companyId,
          dateStart: monthFilter.format('YYYY-MM-DD'),
        })
      );
    }
  }, [companyId, dispatch, managedSilae, monthFilter, payEnabled]);

  useEffect(() => {
    dispatch(
      getCalendarAbsencesRequest({
        date: monthFilter,
        companyId,
        isYearCalendar: monthYearToggle === YEAR,
      })
    );
    localStorage.setItem(
      'CalendarAbsences',
      JSON.stringify({ monthFilter, companyId, monthYearToggle })
    );
  }, [monthFilter, dispatch, companyId, monthYearToggle]);

  const datesArrayForCurrentMonth = useSelector(
    selectDatesArrayForCurrentMonth
  );

  const datesArrayForCurrentYear = useSelector(selectDatesArrayForCurrentYear);

  const year = useSelector(selectCalendarYear);
  const { isDateHoliday } = useHolidays(year);

  const employeesStatus = useSelector(selectEmployeesStatus);
  const absencesStatus = useSelector(selectCalendarAbsencesStatus);
  const isLoading = useMemo(
    () =>
      [employeesStatus, absencesStatus].some((status) =>
        [STATUS_NOT_REQUESTED, STATUS_LOADING].includes(status)
      ),
    [employeesStatus, absencesStatus]
  );

  const isThereNoEmployees = useMemo(
    () =>
      monthYearToggle === MONTH &&
      (filteredEmployees.length === 0 ||
        (withAbsencesOnly && employeesWithAbsencesOnly.length === 0)),
    [
      monthYearToggle,
      filteredEmployees.length,
      withAbsencesOnly,
      employeesWithAbsencesOnly.length,
    ]
  );

  const tableDataSource = withAbsencesOnly
    ? employeesWithAbsencesOnly
    : filteredEmployees;

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    onSelectAll: (isSelected) => {
      if (isSelected) {
        setSelectedRowKeys(tableDataSource.map(({ employeeid }) => employeeid));
      } else {
        setSelectedRowKeys([]);
      }
    },
  };

  const openLeaveModal = useCallback(
    (e) => {
      /*
       * employeeId - id of employee
       * leaveObjId - id of leave object
       * leaveType - type of leave object: either 'absence' or 'vacation'
       * */
      const {
        employeeId,
        leaveType,
        leaveObjId,
        startDate,
        isMorning,
        isAfternoon,
      } = e.target.dataset;

      const employeePageInTable =
        Math.floor(
          tableDataSource.findIndex((el) => el.employeeid === employeeId) / 20
        ) + 1;

      dispatch(toggleVacationModalVisibility());

      const employee =
        unfilteredEmployees.find(
          ({ employeeid }) => employeeid === employeeId
        ) || {};

      const isoStartDate = startDate
        ? getIsoFormatFromDateWithSlashes(startDate)
        : null;

      dispatch(
        setLeaveModalMainData({
          employee: formatEmployeeToLeaveModal(employee),
          otherEmployees: filteredEmployees,
          selectedEmployeesId: selectedRowKeys,
          type: leaveType,
          startDate: isoStartDate,
          isMorning,
          isAfternoon,
          employeePageInTable,
        })
      );
      if (leaveObjId) {
        dispatch(setEditLeaveModalData({ leaveObjId, submitType: 'edit' }));
      }
    },
    [
      dispatch,
      filteredEmployees,
      selectedRowKeys,
      tableDataSource,
      unfilteredEmployees,
    ]
  );

  const downloadAttachment = useCallback(
    (e) => {
      const { leaveObjId, employeeId } = e.target.dataset;

      dispatch(
        downloadAbsenceAttachmentWithoutModal({
          leaveObjId,
          companyId,
          employeeId,
        })
      );
    },
    [companyId, dispatch]
  );

  const absencesMappedToDates = useMemo(
    () =>
      mapAbsencesToDates({
        dates: datesArrayForCurrentMonth,
        absences: calendarAbsences,
      }),
    [calendarAbsences, datesArrayForCurrentMonth]
  );

  const yearAbsencesMappedToDates = useMemo(
    () =>
      mapAbsencesToDates({
        dates: datesArrayForCurrentYear,
        absences: calendarAbsences,
      }),
    [calendarAbsences, datesArrayForCurrentYear]
  );

  const handleDownloadAbsences = useCallback(() => {
    const currentEmployee = filteredEmployees.find(
      (employee) => employee.employeeid === calendarEmployee
    );

    if (monthYearToggle === MONTH) {
      dispatch(
        downloadAbsences({
          filter: {
            companyId,
            start: formatDatesToFilter(datesArrayForCurrentMonth.at(0)),
            end: formatDatesToFilter(datesArrayForCurrentMonth.at(-1)),
            branchId: branchFilter,
          },
        })
      );
    }

    if (monthYearToggle === YEAR && currentEmployee) {
      dispatch(
        downloadAbsences({
          filter: {
            companyId,
            start: formatDatesToFilter(datesArrayForCurrentYear.at(0)),
            end: formatDatesToFilter(datesArrayForCurrentYear.at(-1)),
            name: `${currentEmployee.firstname1} [${currentEmployee.lastname}]`,
          },
        })
      );
    }
  }, [
    companyId,
    datesArrayForCurrentMonth,
    datesArrayForCurrentYear,
    dispatch,
    filteredEmployees,
    monthYearToggle,
    calendarEmployee,
    branchFilter,
  ]);

  const monthViewColumns = useGetCalendarMonthViewColumn(
    isMobileVersion,
    datesArrayForCurrentMonth,
    isDateHoliday,
    absencesMappedToDates,
    openLeaveModal,
    downloadAttachment,
    selectedRowKeys
  );

  const yearViewColumns = useGetCalendarYearViewColumn(
    isMobileVersion,
    isDateHoliday,
    openLeaveModal,
    downloadAttachment,
    selectedRowKeys,
    yearAbsencesMappedToDates,
    monthFilter,
    calendarEmployee
  );

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Typography.Title className={Styles.pageTitle}>
          {CALENDAR}
          <div className={Styles.titleButtonsWrapper}>
            <Button
              sizeAuto
              variant="transparent"
              className={Styles.downloadButton}
              onClick={handleDownloadAbsences}
            >
              <Icon type="download" className={Styles.downloadIcon} />
              {DOWNLOAD}
            </Button>
            {!isMobileVersion && (
              <SendInfoButton cancelHide className={Styles.sendInfoButton} />
            )}
          </div>
        </Typography.Title>
        <CalendarFilters
          monthFilter={monthFilter}
          setMonthFilter={setMonthFilter}
          withAbsencesOnly={withAbsencesOnly}
          setWithAbsencesOnly={setWithAbsencesOnly}
          monthYearToggle={monthYearToggle}
          setMonthYearToggle={setMonthYearToggle}
          filteredEmployees={filteredEmployees}
          calendarEmployee={calendarEmployee}
          setCalendarEmployee={setCalendarEmployee}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div className={Styles.contentRow}>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className={ResultsTableStyles.resultsTable}>
            {monthYearToggle === MONTH && (
              <Table
                columns={monthViewColumns}
                dataSource={tableDataSource}
                className={clsx(Styles.table, {
                  [Styles.overflowHidden]: isThereNoEmployees,
                })}
                pagination={{
                  showTotal: (total, range) =>
                    getTablePaginationRange(range[0], range[1], total),
                  responsive: true,
                  pageSize: 20,
                  hideOnSinglePage: true,
                  size: isMobileVersion ? 'small' : 'default',
                  defaultCurrent: defaultPage,
                  current: currentPage,
                  onChange: (page) => setCurrentPage(page),
                }}
                scroll={tableDataSource?.length ? { x: 1130, y: 450 } : {}}
                rowSelection={rowSelection}
                locale={{ emptyText: USERS_NOT_FOUND }}
              />
            )}

            {monthYearToggle === YEAR && (
              <Table
                columns={yearViewColumns}
                className={clsx(Styles.table, {
                  [Styles.overflowHidden]: isThereNoEmployees,
                })}
                scroll={
                  yearCalendarEmployee?.length ? { x: 1130, y: 1000 } : {}
                }
                dataSource={calendarEmployee ? yearCalendarEmployee : undefined}
                pagination={false}
                locale={{ emptyText: YEAR_VIEW_CALENADR_NO_DATA }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
});
