/* eslint-disable no-useless-escape */
export const FIELD_NAMES = {
  AUTH: {
    EMAIL: 'email',
    OLD_PASSWORD: 'oldPassword',
    NEW_PASSWORD: 'newPassword',
    CONFIRM_NEW_PASSWORD: 'confirmNewPassword',
  },
};

export const emailRegExp = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
