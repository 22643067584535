export const LOGIN_PAGE_TRANSLATIONS = {
  HEADER_TEXT: 'Bienvenue dans votre espace My Exco',
  LABELS: {
    EMAIL: 'Votre email',
    PASSWORD: 'Mot de passe',
    IS_REGISTER: 'Enregistrer',
    LOG_IN: 'Se connecter',
    REGISTER: 'Enregistrer',
  },
  LINKS: {
    LOGIN: 'Se connecter',
    CHANGE_PASSWORD: 'Changer le mot de passe',
    FORGOT_PASSWORD: 'Mot de passe oublié ?',
  },
  ERRORS: {
    FIELD_REQUIRED: 'Ce champ est obligatoire',
    CREDENTIALS_INCORRECT:
      'Votre email et / ou le mot de passe sont incorrects',
    THREE_ATTEMPTS_LEFT:
      "Vos identifiants sont incorrects. Il vous reste 3 tentatives avant que votre compte ne soit désactivé. Si vous avez perdu votre mot de passe, merci d'utiliser la fonctionnalité « mot de passe oublié » ci-dessous.",
    SERVER_ERROR: 'Erreur du serveur',
    EMAIL_VALIDATION_ERROR: 'Entrez un e-mail correct',
  },
};
