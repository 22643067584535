import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Icon } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { Spinner } from 'components/Spinner';
import { STATUS_LOADING, ISO_DATE_FORMAT } from 'const';
import {
  setLeaveModalMainData,
  toggleVacationModalVisibility,
} from 'modules/SalaryModule/Vacations/actions';
import {
  changeEmployeesFilters,
  resetEmployeesFilters,
  setDateRange,
  setEmployeeSchedule,
  setEmployeesSearch,
  fetchEmployeesRequest,
  downloadEmployeePayslips,
  downloadEmployeesTableXlsx,
} from 'modules/SalaryModule/Employees/actions';
import { selectUserCurrentCompany } from 'modules/loggedUserInfo/selectors';
import { FiltersToolbar } from 'components/SalaryComponents/FiltersToolbar';
import { getEmployeeTableCsvCommonFilter } from 'modules/SalaryModule/Employees/utils';
import {
  selectClassificationOptions,
  selectContractOptions,
  selectDateRange,
  selectEmployeesStatus,
  selectFilteredEmployees,
  selectLastSyncTime,
  selectIsSyncing,
  selectFilters,
  selectIsFilterChosen,
  selectNatureEmploymentOptions,
  selectSearchTerm,
} from 'modules/SalaryModule/Employees/selectors';
import { useSimpleMedia } from 'hooks/useMedia';
import {
  selectCalendarDateFilter,
  selectFilteredCalendarEmployees,
} from 'modules/SalaryModule/Calendar/selectors';
import { getCalendarAbsencesRequest } from 'modules/SalaryModule/Calendar/actions';
import { EMPLOYEE_HEADER } from 'const/translations/SalaryEmployeePage';
import { Button } from 'components/ui';
import ButtonStyles from 'components/SalaryComponents/FiltersToolbar/FiltersToolbar.module.css';
import Styles from './EmployeesPage.module.css';
import { EmployeeTable } from './EmployeeTable';
import { EmployeeList } from './EmployeeList';
import { NoDataFound } from './NoDataFound';
import { TimeTableModal } from './TimeTableModal';

const SCREEN_WIDTH_BREAKPOINT_VALUE = 796;
const SCREEN_WIDTH_BREAKPOINT_MEDIA = `(max-width: ${SCREEN_WIDTH_BREAKPOINT_VALUE}px)`;
const { BUTTONS_LABELS } = EMPLOYEE_HEADER;

export const EmployeesPage = memo(() => {
  const dispatch = useDispatch();
  const [scheduleId, setScheduleId] = useState(null);
  const [scheduleActiveMember, setScheduleActiveMember] = useState(null);
  const [isTimeModalVisible, setTimeModalVisibility] = useState(false);
  const loadingStatus = useSelector(selectEmployeesStatus);
  const natureEmploymentsOptions = useSelector(selectNatureEmploymentOptions);
  const classificationsOptions = useSelector(selectClassificationOptions);
  const contractsOptions = useSelector(selectContractOptions);
  const isMobileVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);
  const isFiltersChosen = useSelector(selectIsFilterChosen);
  const employees = useSelector(selectFilteredEmployees);
  const lastSyncTime = useSelector(selectLastSyncTime);
  const isSyncing = useSelector(selectIsSyncing);

  const filters = useSelector(selectFilters);
  const searchTerm = useSelector(selectSearchTerm);
  const dateRange = useSelector(selectDateRange);
  const {
    id: companyId,
    managedSilae,
    payEnabled,
    name: companyName,
  } = useSelector(selectUserCurrentCompany);

  useScrollToTop();

  const hasFilters = useMemo(
    () => Object.values(filters).filter((item) => item !== '').length > 0,
    [filters]
  );

  const onSelectChange = useCallback(
    (name, value) => {
      dispatch(
        changeEmployeesFilters({
          [name]: value || '',
        })
      );
    },
    [dispatch]
  );

  const onSearchChange = useCallback(
    (e) => {
      dispatch(setEmployeesSearch(e.target.value));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    (values) => {
      dispatch(changeEmployeesFilters(values));
    },
    [dispatch]
  );

  const handleEmployeeScheduleChange = useCallback(() => {
    if (scheduleId && scheduleActiveMember && companyId) {
      dispatch(
        setEmployeeSchedule({
          companyId,
          scheduleId,
          employeeId: scheduleActiveMember.employeeid,
        })
      );
      setTimeModalVisibility(false);
    }
  }, [dispatch, scheduleId, scheduleActiveMember, companyId]);

  const resetFilters = useCallback(() => {
    dispatch(resetEmployeesFilters());
  }, [dispatch]);

  const resetSearch = useCallback(() => {
    dispatch(setEmployeesSearch(''));
  }, [dispatch]);

  const onRangeChange = useCallback(
    ([startDate, endDate]) => {
      dispatch(setDateRange({ startDate, endDate }));
    },
    [dispatch]
  );

  const openTimeModal = useCallback(
    (e) => {
      const { employeeid } = e.currentTarget.dataset;
      if (employeeid) {
        const employeeData = employees.find(
          (employee) => employee.employeeid === employeeid
        );
        setScheduleActiveMember(employeeData);
        setScheduleId(employeeData.schedule);
      }
      setTimeModalVisibility(true);
    },
    [employees]
  );

  const filteredEmployees = useSelector(selectFilteredCalendarEmployees);

  const monthFilter = useSelector(selectCalendarDateFilter);
  useEffect(() => {
    dispatch(getCalendarAbsencesRequest({ date: monthFilter, companyId }));
  }, [monthFilter, dispatch, companyId]);

  useEffect(() => {
    if (companyId === 'X-TEST-API' && managedSilae && payEnabled) {
      dispatch(
        fetchEmployeesRequest({
          companyId,
          dateStart: dateRange.startDate.format('YYYY-MM-DD'),
        })
      );
    }
  }, [companyId, dispatch, managedSilae, dateRange.startDate, payEnabled]);

  const openVacationModal = useCallback(
    (e) => {
      const { reason, employeeid } = e.currentTarget.dataset;
      if (employees) {
        const employeeData = employees.find(
          (employee) => employee.employeeid === employeeid
        );

        if (employeeData) {
          dispatch(
            setLeaveModalMainData({
              employee: employeeData,
              otherEmployees: filteredEmployees,
              type: reason,
            })
          );
          dispatch(toggleVacationModalVisibility());
        }
      }
    },
    [dispatch, employees, filteredEmployees]
  );

  const downloadEmployeePayslipsHandler = useCallback(
    (employeeId, name) => () => {
      const { startDate, endDate } = dateRange;
      dispatch(
        downloadEmployeePayslips({
          companyId,
          employeeId,
          startDate: startDate.format(ISO_DATE_FORMAT),
          endDate: endDate.format(ISO_DATE_FORMAT),
          employeeName: name,
        })
      );
    },
    [companyId, dateRange, dispatch]
  );

  const closeTimeModal = useCallback(() => {
    setTimeModalVisibility(false);
  }, []);

  const renderEmployeesList = useCallback(() => {
    if (isFiltersChosen && employees.length === 0) {
      return <NoDataFound onResetFilters={resetFilters} />;
    }

    if (loadingStatus === STATUS_LOADING) {
      return <Spinner />;
    }

    return isMobileVersion ? (
      <EmployeeList
        employees={employees}
        openVacationModal={openVacationModal}
        openTimeModal={openTimeModal}
        managedSilae={managedSilae}
        downloadEmployeePayslipsHandler={downloadEmployeePayslipsHandler}
      />
    ) : (
      <EmployeeTable
        employees={employees}
        openVacationModal={openVacationModal}
        openTimeModal={openTimeModal}
        managedSilae={managedSilae}
        downloadEmployeePayslipsHandler={downloadEmployeePayslipsHandler}
        isTimeModalVisible={isTimeModalVisible}
      />
    );
  }, [
    isFiltersChosen,
    employees,
    loadingStatus,
    isMobileVersion,
    openVacationModal,
    openTimeModal,
    managedSilae,
    downloadEmployeePayslipsHandler,
    isTimeModalVisible,
    resetFilters,
  ]);

  const downloadTableXlsxHandler = useCallback(() => {
    dispatch(
      downloadEmployeesTableXlsx({
        companyId,
        filter: getEmployeeTableCsvCommonFilter(filters, searchTerm, dateRange),
      })
    );
  }, [filters, searchTerm, dateRange, dispatch, companyId]);

  return (
    <div className={Styles.container}>
      <FiltersToolbar
        natureEmploymentsOptions={natureEmploymentsOptions}
        classificationsOptions={classificationsOptions}
        contractsOptions={contractsOptions}
        filters={filters}
        searchTerm={searchTerm}
        dateRange={dateRange}
        hasFilters={hasFilters}
        onSelectChange={onSelectChange}
        onSearchChange={onSearchChange}
        onSubmit={onSubmit}
        onRangeChange={onRangeChange}
        resetSearch={resetSearch}
        companyId={companyId}
        managedSilae={managedSilae}
        companyName={companyName}
        lastSyncTime={lastSyncTime}
        isSyncing={isSyncing}
      />
      {renderEmployeesList()}

      <div className={Styles.buttonWrapper}>
        <Button
          sizeAuto
          variant="transparent"
          className={ButtonStyles.downloadButton}
          onClick={downloadTableXlsxHandler}
        >
          <Icon type="download" className={ButtonStyles.downloadIcon} />
          {BUTTONS_LABELS.DOWNLOAD_LONG}
        </Button>
      </div>

      <TimeTableModal
        isVisible={isTimeModalVisible}
        onClose={closeTimeModal}
        onSubmit={handleEmployeeScheduleChange}
        scheduleId={scheduleId}
        setScheduleId={setScheduleId}
        scheduleActiveMember={scheduleActiveMember}
      />
    </div>
  );
});
