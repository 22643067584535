const NATURE_EMPLOYMENT = 'Nature de l’emploi';
const CLASSIFICATION = 'Classification';
const CONTRACT = 'Contrat';

export const EMPLOYEE_TABLE = {
  HEADER_TITLES: {
    NAME: 'Nom Prénom',
    ESTABLISHMENTS: 'Etablissement',
    NATURE_EMPLOYMENT,
    CLASSIFICATION,
    CONTRACT,
    ENTRY_DATE: 'Date d’entrée',
    RELEASE_DATE: 'Date de sortie',
    SCHEDULE: 'Horaire',
  },
  ACTIONS: {
    ABSENCES: 'Absences',
    LEAVE: 'Congés',
  },
};

export const EMPLOYEE_HEADER = {
  FILTER_PLACEHOLDERS: {
    ESTABLISHMENT: 'Etablissement',
    NATURE_EMPLOYMENT,
    CLASSIFICATION,
    CONTRACT,
    SEARCH_BY_NAME: 'Rechercher par nom',
  },
  FILTER_TITLE: 'Les filtres',
  BUTTONS_LABELS: {
    DOWNLOAD: 'Télécharger',
    DOWNLOAD_LONG: 'Télécharger les salariés',
    DOWNLOAD_PAYSLIP:
      'Télécharger tous les bulletins de salaire sur la période sélectionnée',
    DOWNLOAD_PAYSLIP_SHORT: 'Télécharger tous les bulletins de salaire',
    NEW_EMPLOYEE: 'Ajouter nouveau salarié',
    SAVE: 'Sauvegarder',
    CANCEL: 'Annuler',
  },
  WAITING_MODAL: {
    DOWNLOAD: 'Сhargement',
    TEXT:
      'En fonction du nombre de salariés, le téléchargement de leurs bulletins de salaire peut être long, merci de bien vouloir patienter et de ne pas fermer cette page',
  },
  SINGLE_WAITING_MODAL: {
    DOWNLOAD: 'Сhargement',
    TEXT: 'Génération du document en cours, merci de patienter',
  },
};

export const NO_DATA_FOUND = {
  TEXT_FIRST_LINE: 'Aucun résultat',
  TEXT_SECOND_LINE: 'Veuillez modifier votre demande',
  BUTTON_RESET_FILTERS: 'Effacer les filtres',
};

export const TIME_TABLE_MODAL = {
  TITLE: 'Horaire applicable au salarié',
  TOTAL: 'Total',
  SEPARATOR: 'et',
};

export const SILAE_API_ERRORS = {
  NO_DATA:
    'Aucun bulletin de salaire n’est disponible sur la période sélectionnée',
};

export const SILAE_WARNINGS = {
  EMPLOYEE_TIMEOUT: 'Mise à jour des données en cours, merci de patienter',
};
