import { request } from 'utils/request';

export const getEmployees = ({ companyId, dateStart }) =>
  request.get(`/companies/${companyId}/employees/`, {
    params: { dateStart },
  });

export const setEmployeeSchedule = (payload) =>
  request.post(
    `/companies/${payload.companyId}/schedules/${payload.scheduleId}`,
    {
      employeeId: payload.employeeId,
    }
  );

export const downloadEmployeeTableXLSX = (payload) => {
  const { companyId, query } = payload;

  return request.get(`/companies/${companyId}/employees/xlsx?${query}`, {
    responseType: 'blob',
  });
};

export const fetchDownloadEmployeePayslips = ({
  companyId: dosId,
  employeeId,
  startDate,
  endDate,
}) =>
  request.get(`/companies/${dosId}/employees/${employeeId}/payslips/pdf`, {
    params: {
      startDate,
      endDate,
    },
    responseType: 'blob',
  });

export const fetchDownloadCompanyEmployeesPayslips = ({
  companyId: dosId,
  startDate,
  endDate,
}) =>
  request.get(`/companies/${dosId}/payslips/pdf`, {
    params: {
      startDate,
      endDate,
    },
    responseType: 'blob',
  });
