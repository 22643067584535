import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from 'components/ui';
import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import { MODAL_TYPES } from 'const/ModalContainer';
import { openModal } from 'modules/ModalContainer/actions';
import Styles from 'containers/InvoiceSearchPage/InvoiceSearch.module.css';

const { BUTTONS } = INVOICE_SEARCH_TRANSLATIONS;

const propTypes = {
  selectedRowKeys: PropTypes.array,
  setSelectedRowKeys: PropTypes.func,
  canValidate: PropTypes.bool,
};

export const MassActionsButtons = ({
  selectedRowKeys,
  setSelectedRowKeys,
  canValidate,
}) => {
  const dispatch = useDispatch();

  const handleMassActionsModalOpen = (paidOutside = false) => {
    dispatch(
      openModal({
        type: MODAL_TYPES.massInvoiceActionsModal,
        modalProps: {
          selectedRowKeys,
          setSelectedRowKeys,
          paidOutside,
        },
      })
    );
  };
  const handleMassActionsPaidOutside = () => handleMassActionsModalOpen(true);
  const handleMassActionsNotValidated = () => handleMassActionsModalOpen();

  return (
    <>
      <Button
        disabled={!selectedRowKeys.length}
        outlined
        variant="secondary"
        className={Styles.massActionButton}
        big
        onClick={handleMassActionsPaidOutside}
      >
        {BUTTONS.PAID_OUTSIDE}
      </Button>
      {canValidate && (
        <Button
          disabled={!selectedRowKeys.length}
          outlined
          variant="secondary"
          className={Styles.massActionButton}
          big
          onClick={handleMassActionsNotValidated}
        >
          {BUTTONS.APPROVE}
        </Button>
      )}
    </>
  );
};

MassActionsButtons.propTypes = propTypes;
