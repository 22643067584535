import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_TYPES } from 'const/ModalContainer';
import { openModal } from 'modules/ModalContainer/actions';
import { DATE_FORMAT } from 'const';
import {
  selectUserPreValidateLimit,
  selectUserEmail,
} from 'modules/loggedUserInfo/selectors';
import moment from 'moment';
import { useInvoiceActionsData } from '../useInvoiceActionsData';

export const useValidateActionModal = () => {
  const dispatch = useDispatch();
  const currentUserEmail = useSelector(selectUserEmail);

  const modalProps = useInvoiceActionsData();
  const preValidateLimit = useSelector(selectUserPreValidateLimit);

  return useCallback(() => {
    const {
      isPayableInvoice,
      isValidated,
      canPrevalidate,
      paidOutside,
      documentId,
      preValidated,
      dueDate,
      canValidate,
      amountWithVat,
      date,
      preValidatedBy,
    } = modalProps;

    const isActual = moment(date)
      .add(3, 'y')
      .isAfter(moment());

    if (!isPayableInvoice || !isActual) {
      return null;
    }

    if (
      isValidated ||
      (preValidated && canPrevalidate && preValidatedBy !== currentUserEmail)
    ) {
      dispatch(
        openModal({
          type: MODAL_TYPES.invoiceSearchInfoModal,
          modalProps: {
            ...modalProps,
            isValidateAction: true,
          },
        })
      );

      return null;
    }

    if (canValidate || (canPrevalidate && preValidateLimit > amountWithVat)) {
      dispatch(
        openModal({
          type: MODAL_TYPES.approveModal,
          modalProps: {
            documentId,
            defaultDate: (moment().isBefore(dueDate)
              ? moment(dueDate)
              : moment()
            ).format(DATE_FORMAT),
            preValidated,
            paidOutside,
          },
        })
      );

      return null;
    }

    if (canPrevalidate) {
      dispatch(
        openModal({
          type: MODAL_TYPES.invoiceSearchInfoModal,
          modalProps,
        })
      );

      return null;
    }

    dispatch(
      openModal({
        type: MODAL_TYPES.approveModal,
        modalProps: {
          documentId,
          defaultDate: (moment().isBefore(dueDate)
            ? moment(dueDate)
            : moment()
          ).format(DATE_FORMAT),
          preValidated,
          paidOutside,
          isDefault: true,
        },
      })
    );

    return null;
  }, [currentUserEmail, dispatch, modalProps, preValidateLimit]);
};
