import React, { useState, useCallback, useEffect } from 'react';
import { Form, Typography, Icon, Tooltip } from 'antd';
import { LeafyBackground } from 'components/ui/LeafyBackground';
import { selectPasswordChangeErrorCode } from 'modules/Authentication/selectors';
import {
  passwordChangeRequest,
  temporaryPasswordChangeRequest,
} from 'modules/Authentication/actions';
import { FormCard } from 'components/FormCard/FormCard';
import { Input } from 'components/ui';
import {
  CHANGE_PASSWORD_TRANSLATIONS,
  GENERAL_ERRORS,
} from 'const/translations';
import { ROUTES } from 'const';
import FormCardStyles from 'components/FormCard/FormCard.module.css';
import { FIELD_NAMES, emailRegExp } from 'const/forms';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './ChangePasswordPage.module.css';

const { AUTH } = FIELD_NAMES;
const { LABELS, TITLES, TOOLTIP, ERRORS, BTNS } = CHANGE_PASSWORD_TRANSLATIONS;

export const ChangePasswordPage = () => {
  const dispatch = useDispatch();

  const errorCode = useSelector(selectPasswordChangeErrorCode);

  // TODO: Rewrite password changing using Formik instead of 8 `useState`
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [oldPassword, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');

  const isTemporary =
    window.location.pathname === ROUTES.TEMPORARY_CHANGE_PASSWORD;
  useEffect(() => {
    switch (errorCode) {
      case 400:
      case 404:
        setEmailError(
          isTemporary
            ? ERRORS.TEMPORARY_USER_DOES_NOT_EXIST
            : ERRORS.USER_DOES_NOT_EXIST
        );
        break;
      case 403:
        setPasswordError(ERRORS.PASSWORD_INCORRECT);
        break;
      case 423:
        setEmailError(GENERAL_ERRORS.ACCOUNT_LOCKED);
        break;
      default:
        break;
    }
  }, [isTemporary, errorCode]);

  const handleEmailChange = useCallback(({ target: { value } }) => {
    setEmail(value);
    setEmailError('');
  }, []);

  const handleOldPasswordChange = useCallback(({ target: { value } }) => {
    setPassword(value);
    setPasswordError('');
  }, []);

  const handleNewPasswordChange = useCallback(({ target: { value } }) => {
    setNewPassword(value);
    setNewPasswordError('');
    setConfirmNewPasswordError('');
  }, []);

  const handleConfirmNewPasswordChange = useCallback(
    ({ target: { value } }) => {
      setConfirmNewPassword(value);
      setConfirmNewPasswordError('');
      setNewPasswordError('');
    },
    []
  );

  const validateField = (input) => {
    const name = typeof input === 'string' ? input : input.target.name;

    if (name === AUTH.EMAIL && !email.length) {
      setEmailError(ERRORS.REQUIRED_FIELD);
      return false;
    }
    if (name === AUTH.EMAIL && !emailRegExp.test(email)) {
      setEmailError(ERRORS.EMAIL_VALIDATION_ERROR);
      return false;
    }
    if (name === AUTH.OLD_PASSWORD && !oldPassword.length) {
      setPasswordError(ERRORS.REQUIRED_FIELD);
      return false;
    }
    if (name === AUTH.NEW_PASSWORD && !newPassword.length) {
      setNewPasswordError(ERRORS.REQUIRED_FIELD);
      return false;
    }
    if (
      name === AUTH.NEW_PASSWORD &&
      (!/[0-9]/.test(newPassword) || !/^.{8,}$/.test(newPassword))
    ) {
      setNewPasswordError(ERRORS.PASSWORD_NOT_SECURE);
      return false;
    }
    if (name === AUTH.CONFIRM_NEW_PASSWORD && !confirmNewPassword.length) {
      setConfirmNewPasswordError(ERRORS.REQUIRED_FIELD);
      return false;
    }
    if (
      (name === AUTH.NEW_PASSWORD || name === AUTH.CONFIRM_NEW_PASSWORD) &&
      newPassword &&
      confirmNewPassword &&
      newPassword !== confirmNewPassword
    ) {
      setConfirmNewPasswordError(ERRORS.NOT_SAME_PASSWORD);
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const isValid = [
      validateField(AUTH.EMAIL),
      validateField(AUTH.OLD_PASSWORD),
      validateField(AUTH.NEW_PASSWORD),
      validateField(AUTH.CONFIRM_NEW_PASSWORD),
    ].every(Boolean);
    if (!isValid) {
      return;
    }

    if (isTemporary) {
      dispatch(
        temporaryPasswordChangeRequest({
          email,
          passwordRegister: oldPassword,
          passwordAuthentication: newPassword,
        })
      );
    } else {
      dispatch(
        passwordChangeRequest({
          email,
          oldPassword,
          newPassword,
        })
      );
    }
  };

  return (
    <LeafyBackground>
      <FormCard
        submitText={BTNS.RECORD}
        onSubmit={handleSubmit}
        links={['LOGIN']}
      >
        <Typography.Title className={FormCardStyles.textTitle}>
          {TITLES.PASSWORD_CHANGE_TITLE}
        </Typography.Title>
        <Form.Item
          label={
            <Typography.Paragraph className={FormCardStyles.textLabel}>
              {LABELS.EMAIL}
            </Typography.Paragraph>
          }
          validateStatus={emailError ? 'error' : ''}
          help={emailError}
        >
          <Input
            onChange={handleEmailChange}
            name={AUTH.EMAIL}
            type="email"
            onBlur={validateField}
          />
        </Form.Item>
        <Form.Item
          label={
            <Typography.Paragraph className={FormCardStyles.textLabel}>
              {isTemporary ? LABELS.TEMPORARY : LABELS.FORMER}
            </Typography.Paragraph>
          }
          validateStatus={passwordError ? 'error' : ''}
          help={passwordError}
        >
          <Input.Password
            onChange={handleOldPasswordChange}
            name={AUTH.OLD_PASSWORD}
            onBlur={validateField}
          />
        </Form.Item>
        <Form.Item
          label={
            <Typography.Paragraph className={FormCardStyles.textLabel}>
              {LABELS.NEW}&nbsp;
              <Tooltip
                title={TOOLTIP}
                placement="topLeft"
                overlayClassName={Styles.tooltip}
              >
                <Icon
                  type="exclamation-circle"
                  className={Styles.exclamationIcon}
                />
              </Tooltip>
            </Typography.Paragraph>
          }
          validateStatus={newPasswordError ? 'error' : ''}
          help={newPasswordError}
        >
          <Input.Password
            onChange={handleNewPasswordChange}
            name={AUTH.NEW_PASSWORD}
            onBlur={validateField}
          />
        </Form.Item>
        <Form.Item
          label={
            <Typography.Paragraph className={FormCardStyles.textLabel}>
              {/* TODO: Move non-breaking space as const  */}
              {LABELS.NEW}&nbsp;
              <Typography.Text className={Styles.caption}>
                {LABELS.NEW_ONCE_AGAIN}
              </Typography.Text>
            </Typography.Paragraph>
          }
          validateStatus={confirmNewPasswordError ? 'error' : ''}
          help={confirmNewPasswordError}
        >
          <Input.Password
            onChange={handleConfirmNewPasswordChange}
            name={AUTH.CONFIRM_NEW_PASSWORD}
            onBlur={validateField}
          />
        </Form.Item>
      </FormCard>
    </LeafyBackground>
  );
};
