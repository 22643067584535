import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Icon, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSimpleMedia } from 'hooks/useMedia';
import { UPLOAD_DOCUMENT_TRANSLATIONS } from 'const/translations';
import { selectUserCurrentCompanyPreCompta } from 'modules/loggedUserInfo/selectors';
import {
  EMPTY_FUNCTION,
  UPLOAD_DOCUMENTS_FILE_ERRORS,
  SCREEN_WIDTH_BREAKPOINT_MEDIA,
} from 'const';
import { isFileNameIncorrect, isFileNameRepeat } from 'helpers/common';
import Styles from './File.module.css';

const {
  FILES_LIST: {
    TOOLTIP_TEXT: {
      COUNT_LIMIT_ERROR_MESSAGE_10,
      COUNT_LIMIT_ERROR_MESSAGE_20,
      SIZE_LIMIT_ERROR_MESSAGE_10MO,
      SIZE_LIMIT_ERROR_MESSAGE_25MO,
      TYPE_FORBIDDEN_ERROR_MESSAGE,
      FILE_PATTERN_ERROR_MESSAGE,
      FILE_NAME_REQUIRED_ERROR_MESSAGE,
      FILE_NAME_REPEAT,
    },
  },
} = UPLOAD_DOCUMENT_TRANSLATIONS;
const {
  COUNT_LIMIT_ERROR,
  SIZE_LIMIT_ERROR,
  TYPE_FORBIDDEN_ERROR,
  FILE_PATTERN_ERROR,
  FILE_NAME_REPEATS,
  FILE_NAME_REQUIRED,
} = UPLOAD_DOCUMENTS_FILE_ERRORS;

const propTypes = {
  uid: PropTypes.string,
  name: PropTypes.string,
  errors: PropTypes.shape({
    [COUNT_LIMIT_ERROR]: PropTypes.bool,
    [SIZE_LIMIT_ERROR]: PropTypes.bool,
    [TYPE_FORBIDDEN_ERROR]: PropTypes.bool,
    [FILE_PATTERN_ERROR]: PropTypes.bool,
    [FILE_NAME_REPEATS]: PropTypes.number,
    [FILE_NAME_REQUIRED]: PropTypes.bool,
  }),
  onRemove: PropTypes.func,
  className: PropTypes.string,
  removeIcon: PropTypes.node,
  file: PropTypes.any,
  files: PropTypes.any,
  onFileDownload: PropTypes.func,
  hasDownload: PropTypes.bool,
  isRenameable: PropTypes.bool,
  handleChange: PropTypes.func,
};

export const File = ({
  className,
  removeIcon,
  uid,
  name = '',
  onRemove,
  onFileDownload,
  errors = [],
  file = null,
  files,
  hasDownload = false,
  isRenameable,
  handleChange,
}) => {
  const inputRef = useRef(null);
  const isMobileVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);
  const isPreCompta = useSelector(selectUserCurrentCompanyPreCompta);
  const [isEdit, setIsEdit] = useState(false);

  const isFileIncorrect = isFileNameIncorrect(name);
  const isFilesRepeat = isFileNameRepeat(file, files);

  const onEditClick = () => {
    setIsEdit(true);

    setTimeout(() => {
      inputRef.current.focus();
    }, 50);
  };

  const onChangeName = (value, changeLastName) => {
    const newFiles = [...files];
    const currentFile = newFiles.find((el) => el.uid === uid);
    const lastExtension = currentFile.extension;
    const extension = file.name.split('.').at(-1);

    currentFile.name = [value, extension].join('.');

    if (!lastExtension) {
      currentFile.extension = extension;
    }

    if (changeLastName) {
      currentFile.lastName = currentFile.name
        .split('.')
        .slice(0, -1)
        .join('.');
    }

    handleChange({ fileList: newFiles });
  };

  const onInputChange = ({ target: { value } }) => {
    onChangeName(value);
  };

  const onSave = () => {
    if (isFileIncorrect || isFilesRepeat) {
      return;
    }

    onChangeName(
      name
        .split('.')
        .slice(0, -1)
        .join('.'),
      true
    );
    setIsEdit(false);
  };

  const onCancelClick = () => {
    setIsEdit(false);
    onChangeName(
      file.lastName ||
        file.originFileObj.name
          .split('.')
          .slice(0, -1)
          .join('.'),
      true
    );
  };

  const onEnterPress = (e) => {
    if (e.key === 'Enter') {
      onSave();
    }
  };

  const messages = [];
  if (errors[COUNT_LIMIT_ERROR])
    messages.push(
      isPreCompta ? COUNT_LIMIT_ERROR_MESSAGE_20 : COUNT_LIMIT_ERROR_MESSAGE_10
    );
  if (errors[SIZE_LIMIT_ERROR])
    messages.push(
      isPreCompta
        ? SIZE_LIMIT_ERROR_MESSAGE_25MO
        : SIZE_LIMIT_ERROR_MESSAGE_10MO
    );
  if (errors[TYPE_FORBIDDEN_ERROR]) messages.push(TYPE_FORBIDDEN_ERROR_MESSAGE);
  if (errors[FILE_PATTERN_ERROR]) messages.push(FILE_PATTERN_ERROR_MESSAGE);
  if (errors[FILE_NAME_REPEATS])
    messages.push(
      FILE_NAME_REPEAT.replace('{{AMOUNT}}', errors[FILE_NAME_REPEATS])
    );
  if (errors[FILE_NAME_REQUIRED])
    messages.push(FILE_NAME_REQUIRED_ERROR_MESSAGE);

  const tooltipTitle = messages.join('\n\n');

  const disabled = Object.values(errors).some((err) => !!err);

  const removeFile = useCallback(() => {
    if (onRemove) return onRemove(uid, name);
    return EMPTY_FUNCTION();
  }, [uid, name, onRemove]);
  const handleFileDownload = useCallback(() => {
    if (onFileDownload) onFileDownload(file, name);
  }, [file, name, onFileDownload]);

  return (
    <Tooltip
      title={disabled ? tooltipTitle : null}
      overlayClassName={Styles.tooltip}
      placement="topRight"
      trigger={isEdit || isMobileVersion ? 'click' : 'hover'}
    >
      <div className={clsx(Styles.fileItem, className)}>
        <Icon
          type="file"
          className={clsx(Styles.Icon, Styles.fileIcon, {
            [Styles.disabled]: disabled,
          })}
        />
        {!isEdit ? (
          <>
            <span
              title={name}
              className={clsx(Styles.fileName, {
                [Styles.disabled]: disabled,
                [Styles.download]: hasDownload,
              })}
              onClick={isRenameable ? onEditClick : handleFileDownload}
            >
              {name.length > 70
                ? `${name.substring(0, 52)}...${name.slice(-10)}`
                : name}
            </span>
          </>
        ) : (
          <>
            <input
              ref={inputRef}
              name="fileName"
              value={name
                .split('.')
                .slice(0, -1)
                .join('.')}
              className={Styles.input}
              onChange={onInputChange}
              onBlur={onSave}
              onKeyDown={onEnterPress}
              autoComplete="off"
              placeholder="Nom de fichier"
              required
            />
          </>
        )}

        {!isEdit ? (
          <>
            <div>
              <Icon
                type="exclamation-circle"
                style={{
                  color: 'var(--red)',
                  marginLeft: '5px',
                  visibility: disabled ? 'visible' : 'hidden',
                }}
              />
            </div>

            {isRenameable && (
              <div>
                <EditOutlined
                  className={Styles.editIcon}
                  onClick={onEditClick}
                />
              </div>
            )}
            {onRemove && (
              <div onClick={removeFile}>
                {removeIcon || (
                  <Icon type="delete" className={Styles.removeIcon} />
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <Icon
              type="exclamation-circle"
              style={{
                color: 'var(--red)',
                marginLeft: '5px',
                visibility: disabled ? 'visible' : 'hidden',
              }}
            />
            <CheckCircleOutlined
              className={clsx(Styles.checkIcon, {
                [Styles.disabled]: isFileIncorrect || isFilesRepeat,
              })}
              onClick={onSave}
            />
            <CloseCircleOutlined
              className={Styles.closeIcon}
              onClick={onCancelClick}
              onMouseDown={(e) => e.preventDefault()}
            />
          </>
        )}
      </div>
    </Tooltip>
  );
};

File.propTypes = propTypes;
