import { useCallback } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import Styles from 'assets/styles/main.module.css';

import { UPLOAD_DOCUMENT_TRANSLATIONS } from 'const/translations';
import { selectUserCurrentCompanyPreCompta } from 'modules/loggedUserInfo/selectors';
import {
  isFileForbidden,
  isFileNameIncorrect,
  isFileNameRepeat,
  isFileRequired,
} from 'helpers/common';
import {
  MAX_FILES_SIZE_25MO,
  MAX_FILES_SIZE_10MO,
  UPLOAD_DOCUMENTS_FILE_ERRORS,
} from 'const';
import { openSuccessNotification } from 'helpers/notifications';

const {
  MESSAGES: { REMOVED },
  MODAL: {
    TITLE,
    TEXT,
    TITLE_REMOVE_ALL,
    TEXT_REMOVE_ALL,
    CANCEL_BUTTON_TEXT,
    APPLY_BUTTON_TEXT,
  },
} = UPLOAD_DOCUMENT_TRANSLATIONS;
const {
  COUNT_LIMIT_ERROR,
  SIZE_LIMIT_ERROR,
  TYPE_FORBIDDEN_ERROR,
  FILE_PATTERN_ERROR,
  FILE_NAME_REPEATS,
  FILE_NAME_REQUIRED,
} = UPLOAD_DOCUMENTS_FILE_ERRORS;

const NeoExpertExtensions = ['pdf', 'tiff', 'jpeg', 'jpg', 'bmp', 'png'];

export const useUpload = (files, _setFiles, isRenameable) => {
  const isPreCompta = useSelector(selectUserCurrentCompanyPreCompta);
  const setFiles = useCallback(
    (fileList) => {
      let freeSize =
        (isPreCompta && MAX_FILES_SIZE_25MO) || MAX_FILES_SIZE_10MO;
      const extendedFileList = fileList.map((file, index) => {
        freeSize -= file.size;
        const extension = file.name.split('.').at(-1);
        return {
          ...file,
          errors: {
            [COUNT_LIMIT_ERROR]: isPreCompta ? index > 19 : index > 9,
            [SIZE_LIMIT_ERROR]:
              freeSize < 0 ||
              (!NeoExpertExtensions.includes(extension) &&
                file.size >
                  (isPreCompta ? MAX_FILES_SIZE_25MO : MAX_FILES_SIZE_10MO)),
            [TYPE_FORBIDDEN_ERROR]: isFileForbidden(file),
            [FILE_PATTERN_ERROR]:
              isRenameable && isFileNameIncorrect(file.name),
            [FILE_NAME_REPEATS]:
              isRenameable && isFileNameRepeat(file, fileList),
            [FILE_NAME_REQUIRED]: isRenameable && isFileRequired(file.name),
          },
        };
      });
      _setFiles(extendedFileList);
    },
    [_setFiles, isPreCompta, isRenameable]
  );

  const handleChange = useCallback(({ fileList }) => setFiles(fileList), [
    setFiles,
  ]);

  const handleRemove = useCallback(
    (uid, name) => {
      Modal.confirm({
        title: TITLE,
        icon: false,
        content: TEXT.replace('{{FILE_NAME}}', name),
        className: Styles.modal,
        okText: APPLY_BUTTON_TEXT,
        okButtonProps: {
          className: Styles.modalOkButton,
        },
        cancelText: CANCEL_BUTTON_TEXT,
        cancelButtonProps: {
          className: Styles.modalCancelButton,
        },
        autoFocusButton: null,
        onOk() {
          const newFiles = files.filter((file) => file.uid !== uid);
          setFiles(newFiles);
          openSuccessNotification({
            message: REMOVED.replace('{{FILE_NAME}}', name),
          });
        },
        onCancel() {},
      });
    },
    [files, setFiles]
  );

  const handleReset = useCallback(() => {
    Modal.confirm({
      title: TITLE_REMOVE_ALL,
      icon: false,
      content: TEXT_REMOVE_ALL,
      className: Styles.modal,
      okText: APPLY_BUTTON_TEXT,
      okButtonProps: {
        className: Styles.modalOkButton,
      },
      cancelText: CANCEL_BUTTON_TEXT,
      cancelButtonProps: {
        className: Styles.modalCancelButton,
      },
      onOk() {
        setFiles([]);
      },
      onCancel() {},
    });
  }, [setFiles]);

  return { handleChange, handleRemove, handleReset };
};
