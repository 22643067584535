import React, { useState } from 'react';
import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import { SectionTitle } from 'components/Section/SectionTitle';
import flowersImg from 'assets/images/loginNewBg.svg';
import { SearchResultsTable } from 'components/InvoiceSearch/SearchResultsTable';
import { SearchForm } from 'components/InvoiceSearch/SearchForm';
import Styles from './InvoiceSearch.module.css';

const { title } = INVOICE_SEARCH_TRANSLATIONS;

export const InvoiceSearchPage = () => {
  const [canHaveSelections, setCanHaveSelections] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedInvoicesKeys, setSelectedInvoicesKeys] = useState([]);

  return (
    <div className={Styles.background}>
      <img src={flowersImg} alt="flowers" className={Styles.flowersImg} />
      <div className={Styles.container}>
        <SectionTitle
          title={title}
          titleTextColor="white"
          className={Styles.title}
        />
        <SearchForm
          selectedRowKeys={selectedInvoicesKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          setSelectedInvoicesKeys={setSelectedInvoicesKeys}
          canHaveSelections={canHaveSelections}
          setCanHaveSelections={setCanHaveSelections}
        />
        <SearchResultsTable
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          setSelectedInvoicesKeys={setSelectedInvoicesKeys}
        />
      </div>
    </div>
  );
};
