import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { message } from 'antd';
import PropTypes from 'prop-types';
import { useUpload } from 'hooks/useUpload';
import { useSelector, useDispatch } from 'react-redux';
import { UPLOAD_DOCUMENT_TRANSLATIONS } from 'const/translations';
import { PROP_TYPE_STATUS } from 'const/propTypes';
import { STATUS_ERROR, STATUS_SUCCESS } from 'const';
import { UploadManagerDesktopLayout } from 'components/UploadDocuments/FileUploadManager/UploadManagerDesktopLayout';
import { UploadManagerMobileLayout } from 'components/UploadDocuments/FileUploadManager/UploadManagerMobileLayout';
import { isFileForbidden } from 'helpers/common';
import { openSuccessNotification } from 'helpers/notifications';
import {
  selectUserCurrentCompanyPreCompta,
  selectUserCurrentCompanyPreComptaMailCollector,
  selectUserCurrentCompanyPreComptaMail,
} from 'modules/loggedUserInfo/selectors';
import {
  uploadFilesRequest,
  uploadFilesResetStatus,
} from 'modules/uploadDocuments/actions';
import {
  selectUploadPayload,
  selectUploadErrors,
} from 'helpers/UploadDocuments';

const {
  MESSAGES: { ERROR, FILE_EXISTS, SUCCESS },
} = UPLOAD_DOCUMENT_TRANSLATIONS;

export const FileUploadManager = ({ uploadStatus, isMobileVersion }) => {
  const dispatch = useDispatch();
  const isPreCompta = useSelector(selectUserCurrentCompanyPreCompta);
  const isPreComptaMailCollector = useSelector(
    selectUserCurrentCompanyPreComptaMailCollector
  );
  const preComptaMail = useSelector(selectUserCurrentCompanyPreComptaMail);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if ([STATUS_SUCCESS, STATUS_ERROR].includes(uploadStatus) && submitted) {
      setUploadedFiles([]);
      setSubmitted(false);
      if (uploadStatus === STATUS_SUCCESS) {
        openSuccessNotification({ message: SUCCESS });
      }
      dispatch(uploadFilesResetStatus());
    }
  }, [dispatch, submitted, uploadStatus]);

  const onUploadError = useCallback((errorMessage) => {
    message.error(errorMessage);
  }, []);

  const uploadToServer = useCallback(() => {
    dispatch(
      uploadFilesRequest({
        uploadedFiles: selectUploadPayload(uploadedFiles, isPreCompta),
        onError: onUploadError,
      })
    );
  }, [dispatch, uploadedFiles, isPreCompta, onUploadError]);

  useEffect(() => {
    if (submitted) {
      uploadToServer();
    }
  }, [submitted, uploadToServer]);

  const { handleChange, handleRemove, handleReset } = useUpload(
    uploadedFiles,
    setUploadedFiles,
    true
  );

  const initiateUpload = useCallback(() => setSubmitted(true), []);

  const beforeUpload = useCallback(
    (file) => {
      const fileNames = uploadedFiles.map((item) => item.name);
      if (isFileForbidden(file)) {
        message.error(ERROR.replace('{{FILE_NAME}}', file.name));
        return Promise.reject();
      }
      if (fileNames.includes(file.name)) {
        message.error(FILE_EXISTS.replace('{{FILE_NAME}}', file.name));
        return Promise.reject();
      }
      return false; // do not send to the backend
    },
    [uploadedFiles]
  );

  const listHasValidFiles = useMemo(
    () =>
      !!selectUploadPayload(uploadedFiles, isPreCompta).length &&
      !selectUploadErrors(uploadedFiles).length,
    [isPreCompta, uploadedFiles]
  );

  const UploadManagerLayout = isMobileVersion
    ? UploadManagerMobileLayout
    : UploadManagerDesktopLayout;

  return (
    <UploadManagerLayout
      handleChange={handleChange}
      uploadedFiles={uploadedFiles}
      beforeUpload={beforeUpload}
      handleRemove={handleRemove}
      handleReset={handleReset}
      initiateUpload={initiateUpload}
      submitted={submitted}
      listHasValidFiles={listHasValidFiles}
      isPreCompta={isPreCompta}
      isPreComptaMailCollector={isPreComptaMailCollector}
      preComptaMail={preComptaMail}
      isRenameable
    />
  );
};

FileUploadManager.propTypes = {
  uploadStatus: PROP_TYPE_STATUS,
  isMobileVersion: PropTypes.bool,
};
