import React, { memo, useCallback } from 'react';
import { Row, Col, Typography, Pagination, Icon } from 'antd';
import { CalendarIcon } from 'components/ui/CalendarIcon';
import clsx from 'clsx';
import { EMPLOYEE_TABLE } from 'const/translations/SalaryEmployeePage';
import MainStyles from 'assets/styles/main.module.css';
import PropTypes from 'prop-types';
import { usePaginationHook } from 'modules/SalaryModule/Employees/hooks/usePagination.hook';
import Styles from './EmployeeList.module.css';

const { Title, Paragraph } = Typography;
const { ACTIONS } = EMPLOYEE_TABLE;

const propTypes = {
  employees: PropTypes.array,
  openTimeModal: PropTypes.func,
  openVacationModal: PropTypes.func,
  managedSilae: PropTypes.bool,
  downloadEmployeePayslipsHandler: PropTypes.func,
};

const ITEM_PER_PAGE = 20;

export const EmployeeList = memo(
  ({
    employees,
    openTimeModal,
    openVacationModal,
    managedSilae,
    downloadEmployeePayslipsHandler,
  }) => {
    const {
      sortedData: employeesList,
      page,
      setPage,
      currentDataLength,
    } = usePaginationHook(employees, ITEM_PER_PAGE);

    const handlePageChange = useCallback(
      (pageNumber) => {
        setPage(pageNumber);
      },
      [setPage]
    );

    return (
      <>
        <div className={Styles.list}>
          {employeesList.map((employee) => (
            <div key={employee.key} className={Styles.item}>
              <Row type="flex" justify="space-between" align="middle">
                <Col
                  className={clsx(
                    'ant-typography-ellipsis-single-line',
                    Styles.itemHeader
                  )}
                >
                  <Title
                    className={clsx(
                      MainStyles.heading,
                      MainStyles.headingH3,
                      'ant-typography-ellipsis-single-line'
                    )}
                    level={3}
                  >
                    {employee.name}
                  </Title>
                </Col>
                <Col className={Styles.itemActions}>
                  <span
                    className={clsx(
                      Styles.itemAction,
                      Styles.itemActionAddLeave
                    )}
                    data-reason="absence"
                    data-employeeid={employee.employeeid}
                    onClick={openVacationModal}
                  >
                    + {ACTIONS.ABSENCES}
                  </span>
                  <span
                    className={clsx(
                      Styles.itemAction,
                      Styles.itemActionAddVacation
                    )}
                    data-reason="vacation"
                    data-employeeid={employee.employeeid}
                    onClick={openVacationModal}
                  >
                    + {ACTIONS.LEAVE}
                  </span>
                </Col>
              </Row>
              <Paragraph ellipsis className={Styles.itemDescription}>
                {employee.natureEmployment}, {employee.classification}
              </Paragraph>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className={Styles.actionsWrapper}
              >
                <span>
                  {employee.dateOfEntry} - {employee.releaseDate}
                </span>
                <CalendarIcon
                  className={Styles.icon}
                  data-employeeid={employee.employeeid}
                  onClick={openTimeModal}
                />
                {managedSilae && (
                  <Icon
                    type="download"
                    className={Styles.downloadIcon}
                    onClick={downloadEmployeePayslipsHandler(
                      employee.employeeid
                    )}
                  />
                )}
              </Row>
            </div>
          ))}
        </div>
        <div className={Styles.paginationBlock}>
          <Pagination
            hideOnSinglePage={currentDataLength <= ITEM_PER_PAGE}
            className={MainStyles.pagination}
            defaultCurrent={page}
            total={currentDataLength}
            onChange={handlePageChange}
            pageSize={ITEM_PER_PAGE}
          />
        </div>
      </>
    );
  }
);

EmployeeList.propTypes = propTypes;
