import React from 'react';
import PropTypes from 'prop-types';
import Styles from 'components/UploadDocuments/FileUploadManager/UploadManagerMobileLayout/UploadManagerMobileLayout.module.css';
import { Button, Typography, Upload } from 'antd';
import iconUpload from 'assets/icons/icon-upload.svg';
import { FileListWithTotal } from 'components/UploadDocuments/FileListWithTotal/FileListWithTotal';
import clsx from 'clsx';
import iconMail from 'assets/icons/mail.svg';
import { UPLOAD_DOCUMENT_TRANSLATIONS } from 'const/translations';

const {
  UPLOAD_MOBILE: { BUTTON, DESCRIPTION },
  FILES_LIST: { EMPTY },
  BUTTON_CAPTION,
} = UPLOAD_DOCUMENT_TRANSLATIONS;

const propTypes = {
  uploadedFiles: PropTypes.array,
  beforeUpload: PropTypes.func,
  handleChange: PropTypes.func,
  handleRemove: PropTypes.func,
  handleReset: PropTypes.func,
  submitted: PropTypes.bool,
  initiateUpload: PropTypes.func,
  listHasValidFiles: PropTypes.bool,
  isRenameable: PropTypes.bool,
};

/**
 * Component is used in contact HR modal as well.
 * @param uploadedFiles
 * @param beforeUpload
 * @param handleChange
 * @param handleRemove
 * @param handleReset
 * @param submitted
 * @param initiateUpload
 * @param listHasValidFiles
 * @returns {*}
 * @constructor
 */
export const UploadManagerMobileLayout = ({
  uploadedFiles,
  beforeUpload,
  handleChange,
  handleRemove,
  handleReset,
  submitted,
  initiateUpload,
  listHasValidFiles,
  isRenameable,
}) => (
  <>
    <div className={Styles.uploadWrapper}>
      <Upload
        name="uploader"
        showUploadList={false}
        multiple
        fileList={uploadedFiles}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        <Button variant="outlined" className={Styles.uploadButton}>
          <img
            src={iconUpload}
            alt="icon_upload"
            className={Styles.uploadIcon}
          />
          <Typography.Text className={Styles.uploadButtonTitle}>
            {BUTTON}
          </Typography.Text>
        </Button>
      </Upload>
      <div className={Styles.uploadDescription}>{DESCRIPTION}</div>
      {uploadedFiles.length ? (
        <FileListWithTotal
          files={uploadedFiles}
          onRemove={handleRemove}
          onReset={handleReset}
          isMobileView
          isRenameable={isRenameable}
          handleChange={handleChange}
        />
      ) : (
        <div className={Styles.emptyList}>
          <Typography.Text className={Styles.emptyListText}>
            {EMPTY}
          </Typography.Text>
        </div>
      )}
    </div>
    {initiateUpload && (
      <div className={Styles.upload__controls}>
        <button
          type="button"
          className={clsx(Styles.upload__submitButton, {
            [Styles.disabled]:
              !uploadedFiles.length || !listHasValidFiles || submitted,
          })}
          disabled={!uploadedFiles.length || !listHasValidFiles || submitted}
          onClick={initiateUpload}
        >
          <img
            className={Styles.upload__submitButtonIcon}
            src={iconMail}
            alt=""
          />
          <span className={Styles.buttonText}>{BUTTON_CAPTION}</span>
        </button>
      </div>
    )}
  </>
);

UploadManagerMobileLayout.propTypes = propTypes;
