import { createSelector } from 'reselect';
import moment from 'moment';
import {
  attachAbsencesToEmployees,
  dateStringIntoDate,
  getDatesSequence,
  mapCompanyBranchesToSelect,
  mapEmployeesToCalendarPage,
} from 'modules/SalaryModule/Calendar/helpers';
import { ISO_DATE_FORMAT } from 'const';
import { YEAR_TABLE_DATA_TEMPLATE } from 'const/Calendar';
import {
  selectCompanyBranches,
  selectEmployees,
} from 'modules/SalaryModule/Employees/selectors';
import { selectUserCurrentCompany } from 'modules/loggedUserInfo/selectors';
import { LEAVES_TYPES } from 'const/translations/SalaryCalendar';
const { LEAVE_ABSENCE, LEAVE_VACATION } = LEAVES_TYPES;

export const selectMonthYearToggleState = (state) =>
  state.getIn(['calendarReducer', 'monthYearToggle']);

export const selectCalendarEmployeeState = (state) =>
  state.getIn(['calendarReducer', 'calendarEmployee']);

export const selectCalendarAbsencesStatus = (state) =>
  state.getIn(['calendarReducer', 'absencesStatus']);

export const selectCalendarCompanyBranchesForSelect = createSelector(
  selectCompanyBranches,
  (branches) => mapCompanyBranchesToSelect(branches)
);

export const selectCalendarWithAbsencesOnly = (state) =>
  state.getIn(['calendarReducer', 'withAbsencesOnly']) || false;

export const selectCalendarSearchFilter = (state) =>
  state.getIn(['calendarReducer', 'searchFilter']) || '';

export const selectCalendarBranchFilter = (state) =>
  state.getIn(['calendarReducer', 'branchFilter']) || '';

export const selectCalendarDateFilter = (state) =>
  state.getIn(['calendarReducer', 'dateFilter']) || moment().startOf('month');

export const selectCalendarYear = (state) =>
  state.getIn(['calendarReducer', 'dateFilter']).year() || 0;

export const selectHolidaysUpdating = (state) =>
  state.getIn(['calendarReducer', 'areHolidaysUpdating']) || false;

export const selectYearsWithoutHolidays = (state) =>
  state.getIn(['calendarReducer', 'yearsWithoutHolidays']) || [];

export const selectAbsencesTypes = (state) =>
  state
    .getIn(['calendarReducer', 'absencesTypes'])
    ?.data?.reasons?.filter((el) => el.type === LEAVE_ABSENCE) || [];

export const selectVacationTypes = (state) =>
  state
    .getIn(['calendarReducer', 'absencesTypes'])
    ?.data?.reasons?.filter((el) => el.type === LEAVE_VACATION) || [];

export const selectDatesArrayForCurrentMonth = createSelector(
  selectCalendarDateFilter,
  (monthFilter) => {
    const startDateIso = moment(monthFilter)
      .startOf('month')
      .format(ISO_DATE_FORMAT);
    const endDateIso = moment(startDateIso)
      .endOf('month')
      .format(ISO_DATE_FORMAT);
    return getDatesSequence(startDateIso, endDateIso);
  }
);

export const selectDatesArrayForCurrentYear = createSelector(
  selectCalendarDateFilter,
  (monthFilter) => {
    const startDateIso = moment(monthFilter)
      .startOf('year')
      .format(ISO_DATE_FORMAT);
    const endDateIso = moment(startDateIso)
      .endOf('year')
      .format(ISO_DATE_FORMAT);
    return getDatesSequence(startDateIso, endDateIso);
  }
);

export const selectCalendarEmployees = createSelector(
  selectEmployees,
  (employees) => mapEmployeesToCalendarPage(employees)
);

export const selectCalendarAbsences = createSelector(
  (state) => state.getIn(['calendarReducer', 'absences']),
  (absences) => (absences ? absences.toJS() : [])
);

const selectCalendarEmployeesWithAbsences = createSelector(
  selectCalendarEmployees,
  selectCalendarAbsences,
  (employees, absences) => attachAbsencesToEmployees(employees, absences)
);

export const selectFilteredCalendarEmployees = createSelector(
  selectCalendarSearchFilter,
  selectCalendarBranchFilter,
  selectCalendarDateFilter,
  selectCalendarEmployeesWithAbsences,
  selectUserCurrentCompany,
  (searchFilter, branchFilter, dateFilter, employees, { managedSilae }) =>
    // search query check
    employees.filter((employee) => {
      const { firstname1, lastname } = employee;
      const fullname = String(`${lastname || ''} ${firstname1 || ''}`).trim();
      if (!fullname.toLowerCase().includes(searchFilter.toLowerCase()))
        return false;

      // branch check
      if (
        !managedSilae &&
        String(employee.siret) !== String(branchFilter) &&
        branchFilter !== ''
      ) {
        return false;
      }

      // date range check
      const employeeStartDate = moment(employee.startdate);
      const employeeEndDate = moment(employee.enddate);
      const filterStartDate = moment(dateFilter).startOf('month');
      const filtersEndDate = moment(dateFilter).endOf('month');
      if (
        employeeStartDate.isBefore(filterStartDate) &&
        employeeEndDate.isBefore(filterStartDate) &&
        employee.enddate
      ) {
        return false;
      }
      if (employeeStartDate.isAfter(filtersEndDate)) return false;
      return true;
    })
);

export const selectEmployeesWithAbsencesOnly = createSelector(
  selectCalendarDateFilter,
  selectFilteredCalendarEmployees,
  (monthFilter, filteredEmployees) =>
    filteredEmployees.filter(({ absences }) =>
      Object.keys(absences).some((absenceDate) =>
        moment(dateStringIntoDate(absenceDate)).isSame(monthFilter, 'month')
      )
    )
);

export const selectYearCalendarEmployee = createSelector(
  selectCalendarEmployeeState,
  selectCalendarBranchFilter,
  selectCalendarDateFilter,
  selectCalendarEmployeesWithAbsences,
  (calendarEmployee, branchFilter, dateFilter, employees) => {
    const employeeData = (employees.filter(
      (e) => e.employeeid === calendarEmployee
    ) || [])[0];

    const tableData = JSON.parse(JSON.stringify(YEAR_TABLE_DATA_TEMPLATE));

    Object.keys(employeeData?.absences || {}).forEach((key) => {
      const [day, month] = key.split('/');
      if (month && day) {
        const tableRow = tableData.find((e) => e.key === Number(month));
        tableRow[`day-${Number(day)}`] = {
          ...employeeData,
          absences: { ...employeeData.absences[key] },
          dateString: key,
        };
      }
    });

    return tableData;
  }
);
