import {
  MAX_FILES_SIZE_25MO,
  MAX_FILES_SIZE_10MO,
  UPLOAD_DOCUMENTS_FILE_ERRORS,
} from '../../const';
const { TYPE_FORBIDDEN_ERROR } = UPLOAD_DOCUMENTS_FILE_ERRORS;

/**
 * Upload documents page/contact HR modal
 * @param files
 * @returns {[]}
 */

export const selectUploadPayload = (files, isPreCompta) => {
  const withoutForbiddenFiles = files.filter(
    ({ errors }) => !errors[TYPE_FORBIDDEN_ERROR]
  );

  const limitedByQuantity = withoutForbiddenFiles.slice(
    0,
    isPreCompta ? 20 : 10
  );

  const payload = [];

  limitedByQuantity.reduce((currentSizeSum, nextFile) => {
    if (
      currentSizeSum + nextFile.size <
      ((isPreCompta && MAX_FILES_SIZE_25MO) || MAX_FILES_SIZE_10MO)
    ) {
      payload.push(nextFile);
    }
    return currentSizeSum + nextFile.size;
  }, 0);

  return payload;
};

export const selectUploadErrors = (files) => {
  const fileErrors = files.filter(({ errors }) =>
    Object.values(errors).some((err) => !!err)
  );

  return fileErrors;
};
