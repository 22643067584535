import moment from 'moment';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Col,
  Form,
  Icon,
  Input,
  Modal as antdModal,
  Row,
  Tooltip,
  Typography,
} from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MainStyles from 'assets/styles/main.module.css';
import { CustomSelect } from 'components/ui';
import { Modal } from 'components/ui/Modal';
import { VACATION_MODAL } from 'const/translations/SalaryVacations';
import { isAbsenceInDates } from 'modules/SalaryModule/Calendar/helpers';
import {
  calcDaysAndHours,
  determineIfDatesOverlap,
  formatFileToVacationModal,
  validateAbsenceFormValues,
} from 'modules/SalaryModule/Vacations/utils';
import { useDispatch, useSelector } from 'react-redux';
import { LEAVES_TYPES } from 'const/translations/SalaryCalendar';
import { getLeaveModalNotificationMessage } from 'helpers/salaryHelpers/vacationModal';
import {
  selectAbsencesRequestStatus,
  selectEmployeeAbsences,
  selectVacationModalData,
  selectVacationModalLeaveObject,
  selectVacationModalStartDate,
  selectVacationModalSubmitType,
  selectVacationModalVisibility,
} from 'modules/SalaryModule/Vacations/selectors';
import {
  selectAbsencesTypes,
  selectVacationTypes,
} from 'modules/SalaryModule/Calendar/selectors';
import { ISO_DATE_FORMAT, STATUS_NOT_REQUESTED } from 'const';
import { ChartFilterCheckbox } from 'components/ui/ChartFilterCheckbox';
import { openSuccessNotification as openSuccessNotificationHelper } from 'helpers/notifications';
import { selectUserCompanyId } from 'modules/loggedUserInfo/selectors';
import { selectSchedules } from 'modules/SalaryModule/CompanySettings/selectors';
import {
  createVacation,
  deleteLeaveRequest,
  editLeaveRequest,
  employeeAbsencesRequest,
  resetVacationModalData,
} from 'modules/SalaryModule/Vacations/actions';
import { Message } from 'containers/Salary/CompanySettingsPage/Message';

import { formatNameUpperLastName } from 'modules/SalaryModule/Employees/utils';
import { VacationModalDates } from './VacationModalDates';
import { VacationModalFile } from './VacationModalFile';

import Styles from './AddVacationModal.module.css';
import { VacationModalTotalTime } from './VacationModalTotalTime';

const { Text } = Typography;
const { TextArea } = Input;
const {
  LABEL_TEXTS,
  WORKING_TIME,
  UI,
  CREATE_LEAVE_TITLES,
  EDIT_LEAVE_TITLES,
  SINGLE_ABSENCE_DAY,
  SINGLE_VACATION_DAY,
  SINGLE_VACATION_DAY_ADDITIONAL,
  SINGLE_ABSENCE_DAY_ADDITIONAL,
} = VACATION_MODAL;

const {
  CONFIRM_MODAL_CONTENT,
  ONE_VACATION,
  ONE_ABSENCE,
  CANCEL,
  CONFIRM_MODAL_TITLE,
  DELETE,
} = UI;

const { LEAVE_ABSENCE, LEAVE_VACATION } = LEAVES_TYPES;
const {
  VACATION_TITLE_BULK,
  ABSENCE_TITLE_BULK,
  ERROR_MESSAGE,
  TYPE,
  SELECT_ADDITIONAL_USERS,
  ERROR_DATES_OVERLAP,
  COMMENT,
  TOOLTIP,
  OPTIONAL,
  IN_BULK_VACATION,
  IN_BULK_ABSENCE,
  IN_BULK_VACATION_ADDITIONL,
  IN_BULK_ABSENCE_ADDITIONL,
} = LABEL_TEXTS;

const propTypes = {
  onClose: PropTypes.func,
};

/**
 * @param { String } dateStart - vacation start date
 * @param { String } dateEnd - vacation end date
 * @param { String } dateStartWorkingHours - working time on start date
 * @param { String } dateEndWorkingHours - working time on end date
 * @param { String } absenceType - value from select with leave/absence type options
 * @param { String } comment - optional field for comment
 */
const INITIAL_STATE = {
  dateStart: '',
  dateEnd: '',
  dateStartWorkingTime: WORKING_TIME[0].value,
  dateEndWorkingTime: WORKING_TIME[0].value,
  absenceType: '',
  comment: '',
  addedUsers: [],
  withAddedUsers: false,
};

export const AddVacationModal = memo(({ onClose }) => {
  const dispatch = useDispatch();
  const isVisible = useSelector(selectVacationModalVisibility);

  const modalData = useSelector(selectVacationModalData);
  const schedules = useSelector(selectSchedules);
  const companyId = useSelector(selectUserCompanyId);
  const leaveObj = useSelector(selectVacationModalLeaveObject);
  const submitType = useSelector(selectVacationModalSubmitType);
  const initialStartDate = useSelector(selectVacationModalStartDate);
  const requestStatus = useSelector(selectAbsencesRequestStatus);
  const absencesTypes = useSelector(selectAbsencesTypes);
  const vacationTypes = useSelector(selectVacationTypes);
  const employeeAbsences = useSelector(selectEmployeeAbsences);

  const [file, setFile] = useState([]);
  const [calculatedTime, setCalculatedTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });
  const [formErrors, setFormErrors] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [schedule, setSchedule] = useState(null);
  const [formState, setFormState] = useState(INITIAL_STATE);
  const [isSingleDayAbsence, setIsSingleDayAbsence] = useState(false);

  const cleanErrors = useCallback(() => {
    setFormErrors([]);
  }, [setFormErrors]);

  const handleChange = useCallback(({ target: { value, name } }) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);
  const handleChangeNoTarget = useCallback(
    (shouldCleanErrors = false) => (name, value) => {
      handleChange({ target: { name, value } });

      if (shouldCleanErrors) {
        cleanErrors();
      }
    },
    [cleanErrors, handleChange]
  );

  const toggleIsSingleDayAbsence = useCallback(() => {
    setIsSingleDayAbsence((prev) => !prev);
  }, [setIsSingleDayAbsence]);

  const toggleWithAddedUsers = useCallback(() => {
    handleChangeNoTarget()('withAddedUsers', !formState.withAddedUsers);
  }, [formState.withAddedUsers, handleChangeNoTarget]);

  const currentEmployeeId = modalData?.employee?.employeeid;
  const openedWithSelection = Boolean(modalData?.selectedEmployeesId?.length);
  const absenceId = leaveObj?.id;
  const { dateStart, dateEnd } = formState;

  const filteredEmployeeAbsences = useMemo(
    () =>
      absenceId
        ? employeeAbsences.filter(({ id }) => id !== absenceId)
        : employeeAbsences,
    [employeeAbsences, absenceId]
  );

  const areSelectedDatesOverlap = useMemo(
    () =>
      determineIfDatesOverlap({
        filteredEmployeeAbsences,
        dateEnd,
        dateStart,
        dateStartWorkingTime: formState.dateStartWorkingTime,
        dateEndWorkingTime: formState.dateEndWorkingTime,
        isMorning: modalData?.isMorning || false,
        isAfternoon: modalData?.isAfternoon || false,
      }),
    [filteredEmployeeAbsences, dateEnd, dateStart, modalData, formState]
  );

  useEffect(() => {
    const addedUsers = openedWithSelection
      ? modalData?.selectedEmployeesId
      : modalData?.selectedEmployeesId?.filter(
          (id) => id !== modalData?.employee?.employeeid
        );

    setFormState({
      ...INITIAL_STATE,
      addedUsers,
    });

    handleChangeNoTarget()('withAddedUsers', openedWithSelection);
  }, [handleChangeNoTarget, openedWithSelection, modalData]);

  useEffect(() => {
    if (currentEmployeeId && requestStatus === STATUS_NOT_REQUESTED) {
      dispatch(
        employeeAbsencesRequest({ companyId, employeeId: currentEmployeeId })
      );
    }
  }, [dispatch, currentEmployeeId, companyId, requestStatus]);

  useEffect(() => {
    if (!isVisible) {
      setFormState(INITIAL_STATE);
      setFormErrors([]);
      setFile([]);
      setSchedule(null);
      handleChangeNoTarget()('withAddedUsers', false);
      dispatch(resetVacationModalData());
    }
  }, [isVisible, dispatch, setIsSingleDayAbsence, handleChangeNoTarget]);

  useEffect(() => {
    if (isVisible) {
      setIsSingleDayAbsence(false);
    }
  }, [setIsSingleDayAbsence, isVisible]);

  useEffect(() => {
    if (
      formState.dateStart &&
      (formState.dateStart.isAfter(formState.dateEnd) || !formState.dateEnd)
    ) {
      setFormState((prev) => ({
        ...prev,
        dateEnd: moment(prev.dateStart).add(1, 'days'),
      }));
    }
    if (
      isSingleDayAbsence &&
      formState.dateStart &&
      !formState.dateStart.isSame(formState.dateEnd, 'day')
    ) {
      setFormState((prev) => ({
        ...prev,
        dateEnd: moment(prev.dateStart),
      }));
    }

    if (schedule && !formErrors.length) {
      setCalculatedTime(
        calcDaysAndHours({
          type: modalData?.type,
          schedule,
          start: formState.dateStart || '',
          end: formState.dateEnd || '',
          startTimeOfTheDay: formState.dateStartWorkingTime,
          endTimeOfTheDay: formState.dateEndWorkingTime,
        })
      );
    }
  }, [formState, schedule, formErrors, modalData, isSingleDayAbsence]);

  useEffect(() => {
    if (modalData) {
      const options = (modalData.type === LEAVE_ABSENCE
        ? absencesTypes
        : vacationTypes
      ).map((el) => ({ value: el.id, label: el.title }));
      setTypeOptions(options);

      const employeeSchedule = schedules.find(
        ({ id }) => id === modalData.employee.schedule
      );
      setSchedule(employeeSchedule);

      // set form initial data for create absence
      if (!leaveObj) {
        setFormState((prev) => ({
          ...prev,
          absenceType: '',
          dateStart: initialStartDate ? moment(initialStartDate) : '',
        }));
        return;
      }

      // set form initial data from absence if its edit modal
      setFormState({
        dateStart: moment(leaveObj.start),
        dateEnd: moment(leaveObj.end),
        dateStartWorkingTime: leaveObj.startTimeOfTheDay,
        dateEndWorkingTime: leaveObj.endTimeOfTheDay,
        absenceType: leaveObj.reason.id,
        comment: leaveObj.comment,
      });

      if (moment(leaveObj.start).isSame(moment(leaveObj.end), 'day')) {
        setIsSingleDayAbsence(true);
      }

      const { name: fileName, id: fileId } = leaveObj.supportingDoc;
      setFile(fileId ? [formatFileToVacationModal({ fileName, fileId })] : []);
    }
  }, [
    absencesTypes,
    initialStartDate,
    leaveObj,
    modalData,
    schedules,
    vacationTypes,
  ]);

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      dateEnd: isSingleDayAbsence ? moment(prev.dateStart) : '',
    }));
  }, [isSingleDayAbsence, setFormState]);

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      dateEndWorkingTime: isSingleDayAbsence
        ? formState.dateStartWorkingTime
        : formState.dateEndWorkingTime,
    }));
  }, [
    isSingleDayAbsence,
    formState.dateStartWorkingTime,
    formState.dateEndWorkingTime,
    setFormState,
  ]);

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      addedUsers: prev?.addedUsers?.filter((employeeId) =>
        modalData?.otherEmployees?.find(
          ({ absences, employeeid }) =>
            employeeId === employeeid &&
            !Object.values(absences).find((absence) =>
              isAbsenceInDates(absence, [
                formState.dateStart,
                formState.dateEnd,
              ])
            )
        )
      ),
    }));
  }, [formState.dateStart, formState.dateEnd, modalData]);

  const allowedForAdditionUsers = modalData?.otherEmployees?.filter(
    ({ absences, employeeid }) => {
      if (currentEmployeeId === employeeid && !openedWithSelection) {
        return false;
      }

      return !Object.values(absences).find((absence) =>
        isAbsenceInDates(absence, [formState.dateStart, formState.dateEnd])
      );
    }
  );
  const multipleUsersOptions = allowedForAdditionUsers?.map(
    ({ firstname1, lastname, employeeid }) => ({
      label: formatNameUpperLastName(firstname1, lastname),
      value: employeeid,
    })
  );
  const addedUsersNames = allowedForAdditionUsers
    ?.filter(({ employeeid }) => formState.addedUsers?.includes(employeeid))
    .map(({ firstname1, lastname }) => `${firstname1} ${lastname}`);

  const openSuccessNotification = useCallback(
    (leaveType, employeeName) => {
      const preNotificationText =
        leaveType === LEAVE_VACATION ? ONE_VACATION : ONE_ABSENCE;

      const messageAbout = openedWithSelection
        ? addedUsersNames
        : [employeeName, ...addedUsersNames];

      openSuccessNotificationHelper({
        message: getLeaveModalNotificationMessage(
          leaveType,
          preNotificationText,
          messageAbout
        ),
      });
    },
    [addedUsersNames, openedWithSelection]
  );

  const handleSubmit = useCallback(() => {
    const errors = validateAbsenceFormValues(formState);
    const submitActionCreator =
      submitType === 'create' ? createVacation : editLeaveRequest;

    if (!errors.length && companyId && modalData && !areSelectedDatesOverlap) {
      const singleUserEmployeeId = formState.addedUsers?.length
        ? [modalData.employee.employeeid, ...formState.addedUsers]
        : modalData.employee.employeeid;

      // In case after opening with a selection other users will be removed except one;
      const multipleUsersEmployeeId =
        formState.addedUsers?.length > 1
          ? formState.addedUsers
          : formState.addedUsers?.at(0);

      const employeeId = openedWithSelection
        ? multipleUsersEmployeeId
        : singleUserEmployeeId;

      dispatch(
        submitActionCreator({
          file: file.length ? file[0] : null,
          companyId,
          employeeId,
          data: {
            reasonId: formState.absenceType,
            start: formState.dateStart.format(ISO_DATE_FORMAT),
            startTimeOfTheDay: formState.dateStartWorkingTime,
            endTimeOfTheDay: formState.dateEndWorkingTime,
            end: formState.dateEnd.format(ISO_DATE_FORMAT),
            comment: formState.comment,
          },
          leaveObj,
          onSuccess: openSuccessNotification,
          employeeName: modalData.employee.name,
          employeePageInTable: modalData.employeePageInTable,
        })
      );
      onClose();
    } else {
      setFormErrors(errors);
    }
  }, [
    areSelectedDatesOverlap,
    companyId,
    dispatch,
    file,
    formState,
    leaveObj,
    modalData,
    onClose,
    openSuccessNotification,
    openedWithSelection,
    submitType,
  ]);

  const onLeaveDelete = useCallback(() => {
    antdModal.confirm({
      title: CONFIRM_MODAL_TITLE,
      icon: false,
      content: CONFIRM_MODAL_CONTENT,
      className: MainStyles.modal,
      okText: DELETE,
      okButtonProps: { className: MainStyles.modalOkButton },
      autoFocusButton: null,
      cancelText: CANCEL,
      cancelButtonProps: { className: MainStyles.modalCancelButton },
      onOk() {
        dispatch(deleteLeaveRequest({ id: leaveObj.id }));
        onClose();
      },
      onCancel() {},
    });
  }, [dispatch, leaveObj, onClose]);

  const handleFileUpdate = (newFile) => {
    setFile(newFile);
    if (leaveObj?.id) {
      dispatch(
        editLeaveRequest({
          file: newFile.length ? newFile[0] : null,
          companyId,
          leaveObj,
        })
      );
    }
  };

  const modalTitle = useMemo(() => {
    if (!modalData) {
      return '';
    }

    const titleInBulk =
      modalData.type === LEAVE_VACATION
        ? VACATION_TITLE_BULK
        : ABSENCE_TITLE_BULK;

    const TITLES =
      submitType === 'edit' ? EDIT_LEAVE_TITLES : CREATE_LEAVE_TITLES;
    const titleSingle = `${TITLES[modalData.type]} ${modalData.employee?.name ||
      ''}`;

    return modalData.selectedEmployeesId?.length ? titleInBulk : titleSingle;
  }, [submitType, modalData]);

  return (
    <Modal
      title={modalTitle}
      visible={isVisible}
      onClose={onClose}
      onSubmit={handleSubmit}
      hasDeleteButton={!!leaveObj}
      onDelete={onLeaveDelete}
      deleteText={DELETE}
      extraInfoMessage={
        formErrors.length > 0 && (
          <Message className={Styles.infoMessage} type="error">
            {ERROR_MESSAGE}
          </Message>
        )
      }
    >
      <Form>
        <Row type="flex" className={Styles.row}>
          <Col xs={{ span: 24 }} sm={{ span: 6 }} className={Styles.col}>
            <Text className={Styles.formLabel}>{TYPE}</Text>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 18 }} className={Styles.col}>
            <CustomSelect
              size="large"
              className={clsx(Styles.select, {
                [Styles.errorField]: formErrors.includes('absenceType'),
              })}
              options={typeOptions}
              placeholder=""
              name="absenceType"
              value={formState.absenceType}
              onChange={handleChangeNoTarget(true)}
            />
          </Col>
        </Row>
        {submitType === 'create' && (
          <Row type="flex" className={Styles.row}>
            <Col xs={{ span: 24 }} sm={{ span: 6 }} className={Styles.col}>
              <Text className={Styles.formLabel}>
                {modalData?.type === LEAVE_VACATION
                  ? IN_BULK_VACATION
                  : IN_BULK_ABSENCE}
              </Text>
              <Text className={Styles.formHelp}>
                {modalData?.type === LEAVE_VACATION
                  ? IN_BULK_VACATION_ADDITIONL
                  : IN_BULK_ABSENCE_ADDITIONL}
              </Text>
            </Col>
            <Col className={Styles.col}>
              <ChartFilterCheckbox
                disabled={openedWithSelection}
                toggleChecked={toggleWithAddedUsers}
                id="withAddedUsers"
                checked={formState.withAddedUsers}
              />
            </Col>
            {formState.withAddedUsers && (
              <Col xs={{ span: 24 }} sm={{ span: 16 }} className={Styles.col}>
                <CustomSelect
                  mode="multiple"
                  className={clsx(Styles.select, {
                    [Styles.errorField]: formErrors.includes('withAddedUsers'),
                  })}
                  options={multipleUsersOptions}
                  onChange={handleChangeNoTarget()}
                  value={formState.addedUsers}
                  placeholder={SELECT_ADDITIONAL_USERS}
                  name="addedUsers"
                />
              </Col>
            )}
          </Row>
        )}
        <Row type="flex" className={Styles.row}>
          <Col xs={{ span: 24 }} sm={{ span: 6 }} className={Styles.col}>
            <div className={Styles.labelWrapper}>
              <Text className={Styles.formLabel}>
                {modalData?.type === LEAVE_VACATION
                  ? SINGLE_VACATION_DAY
                  : SINGLE_ABSENCE_DAY}
              </Text>
              <Text className={Styles.formHelp}>
                {modalData?.type === LEAVE_VACATION
                  ? SINGLE_VACATION_DAY_ADDITIONAL
                  : SINGLE_ABSENCE_DAY_ADDITIONAL}
              </Text>
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 18 }} className={Styles.col}>
            <ChartFilterCheckbox
              toggleChecked={toggleIsSingleDayAbsence}
              id="addVacationModalSingleDay"
              checked={isSingleDayAbsence}
            />
          </Col>
        </Row>
        {areSelectedDatesOverlap && (
          <div className={Styles.errorOverlap}>{ERROR_DATES_OVERLAP}</div>
        )}
        <VacationModalDates
          formErrors={formErrors}
          formState={formState}
          setFormState={setFormState}
          handleChange={handleChange}
          cleanErrors={cleanErrors}
          isSingleDayAbsence={isSingleDayAbsence}
        />
        <VacationModalTotalTime calculatedTime={calculatedTime} />
        <VacationModalFile file={file} setFile={handleFileUpdate} hasDownload />
        <Row type="flex" className={Styles.row}>
          <Col xs={{ span: 24 }} sm={{ span: 6 }} className={Styles.col}>
            <div className={Styles.labelWrapper}>
              <Text className={Styles.formLabel}>
                {COMMENT}
                <Tooltip
                  placement="topLeft"
                  arrowPointAtCenter
                  overlayClassName={Styles.tooltipOverlay}
                  title={TOOLTIP}
                >
                  <Icon className={Styles.icon} type="info-circle" />
                </Tooltip>
              </Text>
              <Text className={Styles.formHelp}>{OPTIONAL}</Text>
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 18 }} className={Styles.col}>
            <TextArea
              cols={2}
              value={formState.comment}
              onChange={handleChange}
              name="comment"
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});

AddVacationModal.propTypes = propTypes;
